@use '../style/variables';

.gridContainer {
  :global {
    .ag-theme-bootstrap {
      /* set vertical grid lines for agGrid */
      .ag-cell-value {
        border-left: 1px solid variables.$color-mercury;
      }
    }
  }
}
