.consolidationFilingGroupModal {
  :global {
    .modal-footer {
      display: none;
    }

    .a-form-error {
      margin-bottom: 1em;
    }
  }
}
