@use '../../../../../style/variables';

.validationWarningContainer {
  display: flex;
  align-items: center;
  height: 56px;
  margin-bottom: 16px;
  border-radius: variables.$border-radius-size-select;
  padding: 15px;
  background-color: variables.$warning-status-color;

  > p {
    margin: 0;
    color: variables.$color-dark-3;
    font-size: 16px;
    font-weight: 500;
  }

  &.validationWarningIcon {
    margin-right: 16px;
    color: variables.$color-dark-3;
  }
}
