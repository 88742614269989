.sortOptionSelect {
  margin-bottom: 30px;

  :global {
    .sdk-custom-select-wrapper {
      .Select-menu-outer {
        top: 35px;
      }
    }
  }
}
