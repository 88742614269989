@use '../../../../style/_variables.scss';

.overridableFormInput {
  :global {
    .a-input-compound {
      .a-input-text {
        border: unset;
        padding-left: 15px;
        background-color: transparent;

        &:focus {
          background-color: variables.$color-white;
        }

        &:disabled {
          cursor: default;
        }
      }
    }
  }
}
