@use '../../../style/variables';

.filingDecisionAmountDetails {
  :global {
    .ag-theme-bootstrap {
      .total-row-cell {
        border-top: 2px solid variables.$color-alto;
        font-weight: bold;
      }
    }
  }
}
