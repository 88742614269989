@use '../../../../../style/variables';

$signature-declaration-height: 300px;

.signatureDeclarationContainer {
  display: flex;
  flex-direction: column;
  gap: variables.$efile-current-return-filing-sub-gap-value;
  width: 70%;

  .signatureDeclarationTextContainer {
    border: solid 1px variables.$color-pwc-appkit-grey;
    border-radius: variables.$border-radius-size-select;
    padding: 15px;

    .signatureDeclarationText {
      height: $signature-declaration-height;
      overflow-y: auto;
      padding-right: 10px;
    }
  }

  :global {
    .spinner-container {
      height: $signature-declaration-height;
    }
  }
}
