@use '../../../style/variables';

.optionsWrapper {
  max-width: 400px;
  max-height: 450px;
  overflow-y: scroll;
  word-break: break-all;
}

.selectOptionContainer {
  width: 100%;
  padding: 3px 12px 4px 12px;
}

.selectOptionContainer:hover {
  width: 100%;
  padding: 3px 12px 4px 12px;
  background-color: variables.$color-orange-1;
  color: variables.$color-white;
}

.clearOption {
  font-style: italic;
}
