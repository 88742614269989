@use '../../../style/variables';

.noClientsWrapper {
  display: flex;
  width: 100%;
  height: calc(
    100% - #{variables.$footer-margin} - #{variables.$footer-margin} - #{variables.$footer-line-height}
  );
  justify-content: center;
  align-items: center;
}
