@use "../../../style/variables";

.subheader {
  display: flex;
  align-items: center;
  gap: variables.$efile-current-return-filing-sub-gap-value;
  color: variables.$color-dark-3;
  font-size: 12px;
}

.tabSection {
  display: flex;
  height: 84px;
  border-radius: variables.$border-radius-size-select;
  padding: 24px 50px;
  background-color: variables.$color-white;

  .tabs {
    width: 50%;
  }
}

.mainPage {
  flex-direction: column;
  padding: 24px 64px 24px 32px;
  gap: 20px;
  flex: 1;
  flex-wrap: nowrap;
}

.tableFormat {
  display: flex;
  height: 80%;
  flex-direction: column;
  justify-content: center;
  gap: variables.$efile-current-return-filing-sub-gap-value;
}

.refreshDataBtn {
  margin-right: 0;
  margin-left: auto;
}
