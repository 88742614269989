@use '../../../../style/variables';

.reorderModal {
  width: 600px;
  max-width: 600px;

  :global {
    .ag-grid-custom .ag-theme-bootstrap:not(.ag-popup) {
      height: 300px;
      border: 1px solid variables.$color-light-grey;
    }
  }
}

.reorderModalEditButtons {
  padding-bottom: 1em;
  text-align: end;
}

button {
  margin-left: 8px;
}
