@use '../../../style/variables';

.editSelectionListForm {
  display: flex;
  height: 100%;
  flex-direction: column;
  border-radius: 3px;
  padding: 0 15px;
  background-color: variables.$color-white;
}

.buttonsGroup {
  margin: 1rem 0;
}
