@use '../../../style/z-indexes';

.spinner {
  position: absolute;
  top: 0;
  left: 1;
  width: 98%;
  height: 100%;
  background-color: white;
  opacity: 0.8;
  z-index: z-indexes.$spinner-z-index;
}
