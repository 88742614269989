@use '../style/_variables.scss';

.memberReturnsGrids {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 0 20px;

  .grid-auto-sizing-wrapper {
    height: unset;
  }
}

.consolGridHeader {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.gridTitle {
  margin-right: 30px;
  color: variables.$color-dark-3;
  font-size: 16px;
  font-weight: 500;
}

.consolFormName {
  @extend .gridTitle;

  color: variables.$color-orange-1;
  font-size: 14px;
}

.consolidationDetailsGridWrapper {
  height: auto;
  min-height: 100px;
}

.memberDetailsHelp {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 10px 0;

  .memberDetailsTooltip {
    display: flex;
    align-items: baseline;

    .text {
      font-size: 12px;
    }

    .tooltip {
      margin-left: 5px;
    }
  }
}

.memberGridHeader {
  display: flex;
  position: absolute;
  align-items: center;
  width: 70%;
}

.tabsWrapper {
  flex: 1 1 auto;
  z-index: 2;
}

.memberGridContainer {
  position: relative;
  height: 100%;
}

.memberReturnDetailsGridWrapper {
  :global {
    .ag-theme-bootstrap {
      .ag-cell-value {
        &.editable-cell {
          padding-right: 10px;

          &.selection-cell::after {
            content: '';
            position: absolute;
            top: 0;
            right: 3px;
            bottom: 0;
            width: 6px;
            height: 6px;
            margin: auto 0;
            border: 1px solid;
            border-top: none;
            border-left: none;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}

.redTextWrapper {
  color: variables.$color-red;
}
