@use '../../../style/variables';

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 2px;
  border-style: dashed;
  border-radius: 2px;
  border-color: variables.$form-control-border-color;
  outline: none;
  padding: 20px;
  color: variables.$text-color;
  transition: border 0.24s ease-in-out;

  &--active {
    border-color: variables.$active-tab-color;
  }

  &--accept {
    border-color: variables.$success-color;
  }

  &--reject {
    border-color: variables.$attachment-upload-error-color;
  }
}

.chooseFileButton {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.dropzoneEmptyFile {
  border-color: variables.$color-red;

  span {
    color: variables.$color-red;
  }
}
