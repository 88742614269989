@use '../../../style/variables';

$error-status-background-color: variables.$error-status-color;
$success-status-background-color: variables.$success-status-color;
$warning-status-background-color: variables.$warning-status-color;

$status-text-color: variables.$color-white;
$warning-status-text-color: variables.$color-black;

.status {
  padding: 0.5em;
  color: $status-text-color;
  font-weight: bold;
}

.dangerStatus {
  @extend .status;

  background-color: $error-status-background-color;
}

.successStatus {
  @extend .status;

  background-color: $success-status-background-color;
}

.infoStatus {
  @extend .status;

  background-color: $warning-status-background-color;
}

.warningStatus {
  @extend .status;

  background-color: $warning-status-background-color;
  color: $warning-status-text-color;
}
