@use '../../../../style/variables';

$error-status-background-color: variables.$error-status-color;
$success-status-background-color: variables.$success-status-color;
$warning-status-background-color: variables.$warning-status-color;

$status-text-color: variables.$color-white;
$warning-status-text-color: variables.$color-black;

.grid {
  :global {
    .spinner {
      width: 12px;
      height: 12px;
    }

    .spinner-container {
      width: 100px;
      margin-top: 5px;
      text-align: left;
    }
  }

  display: grid;
  grid-template-rows: repeat(2, 30px);
  grid-template-columns: repeat(5, minmax(70px, auto));
  grid-column-gap: 15px;
  font-size: variables.$regular-font-size;

  p {
    margin: 0.3em 0 0.7em;
  }

  .compileInfoStatus {
    grid-column: 4 / 5;
  }

  .dataItems {
    grid-column: 1 / 2;
  }

  .lastSuccessfulCompileLabel,
  .currentCompileStatus {
    margin-left: 50px;
  }

  .currentCompileStatus {
    grid-row: 2 / 3;
    grid-column: 5 / 6;
  }
}

.defaultRender {
  flex: 0.35;
}

.loadingText {
  margin-left: 10px;
  animation: loading 1.5s infinite alternate;
}

@keyframes loading {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}
