@use '../style/_variables.scss';
@use "../style/z-indexes";

$gutter-space: 15px;

.formSelectWrapper {
  justify-content: center;

  label {
    display: inline;
  }

  :global {
    .sdk-custom-select-wrapper {
      margin-top: 6px;
    }
  }
}

.buttonControls {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .instanceDataLink:hover {
    text-decoration: none;
  }

  .actionMenuColumn {
    margin-left: 8px;
  }

  button {
    margin-left: 8px;
  }
}

.pdfContainer {
  position: relative;
  width: calc(100vw - #{variables.$slide-in-width} - #{$gutter-space});
  height: 100%; /* keep loading spinner in center */
  margin-top: 10px;
  overflow-y: auto;
  overflow-x: auto;
}

.pdf {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  clip: rect(0, auto, auto, 0);

  :global {
    #page-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: hidden;
      overflow-x: auto;
    }

    /* Override of print-service class to make datepickers visible outside of page */
    .pf {
      overflow: visible;

      img {
        mix-blend-mode: multiply;
      }
    }

    .form-wrapper {
      position: relative;
      width: fit-content;
      height: 100%;
      margin: 0 auto;

      .pdf-form-content {
        display: none;

        &.visible {
          display: block;
        }

        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6 {
          margin-bottom: 0;
        }
      }
    }
  }
}

.downloadButton {
  display: flex;
  justify-content: center;
  align-items: center;

  :global {
    .spinner {
      margin-left: 8px;
    }
  }
}

.ofPages {
  margin-right: 10px;
  font-size: variables.$regular-font-size;
}

.pageSection {
  display: flex;
  position: absolute;
  top: 24px;
  right: 20px;
  min-width: 120px;
  height: 30px;
  margin: 3px 15px 2px;
  align-items: center;
  justify-content: flex-end;

  :global {
    .spinner-container--small {
      height: 20px;
    }
  }
}

.pageDropdown {
  :global {
    .sdk-custom-select-global-context-dropdown {
      width: 90px;
      margin: 2px 5px 0 0;

      .global-context-dropdown-wrapper {
        width: 100%;
      }
    }
  }
}

.actionMenu {
  margin: 28px 10px 0 auto;
}

.dropdownLabel {
  margin-left: 12px;
  font-size: variables.$medium-font-size;
  font-weight: 500;
}

.formSelectRow {
  position: relative;
}
