@use '../style/variables';

.calcStatus {
  display: flex;
  align-items: center;
  font-size: 0.6875rem;
}

.calcStatusIndicator {
  margin-right: 0.25rem;
}

.lastUpdatedDate {
  color: variables.$color-gray;
  font-size: 11px;
}

.federalInformationPanel {
  margin: 0 0 10px;
  border: 1px solid variables.$color-alto;
  border-radius: 3px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.08);
  padding: 1.875rem 1.875rem 1.25rem;
  background-color: variables.$color-white;
  font-size: 0.875rem;
}

.federalInformationPanelHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.federalInformationLabel {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.federalInformationField {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
