@use '../style/_variables.scss';

.binderInformation {
  font-size: 0.8rem;
  font-weight: bold;
}

.binderInformationContext {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  .binderInformationParamDropdown {
    width: variables.$slide-in-width;
    margin-right: 10px;
  }

  .binderInformationPdfDate {
    display: flex;
    align-self: end;
    margin-bottom: 10px;
  }
}

:global {
  .add-button {
    min-width: fit-content;
  }
}

.binderGrid {
  :global {
    .ag-header-cell-label {
      margin-left: 0 !important;
    }
  }
}
