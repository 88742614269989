@use '../../../style/variables';

.agGridContainer {
  :global {
    .grid-auto-sizing-wrapper {
      border: 1px solid variables.$color-pwc-appkit-grey;
    }

    .ag-grid-custom {
      height: calc(100vh - 202px);
    }
  }
}

.addJurisdictionModal:global(.modal-dialog) {
  width: 400px;
  min-width: 0;
}
