.coming-soon-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;

  .coming-soon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .coming-soon-paragraph {
    margin: 0;
    padding: 0;
    font-size: 32px;
  }

  .time-icon {
    margin-right: 10px;
  }
}
