@use '../../style/_variables.scss';

@mixin global-list-item($color) {
  &:global(.a-list-item) {
    min-height: 22px;
    padding: 0 10px;
    color: $color;
    font-size: 12px;
  }
}

.listItem {
  @include global-list-item(variables.$color-grey-inactive);

  color: variables.$color-gray;
  cursor: pointer;

  &.activeFolder {
    color: variables.$color-dark-3;
  }
}

.listItemDisabled {
  @include global-list-item(variables.$color-grey-dark);
}

.listIcon {
  display: flex;
  margin-right: 6px;
}

.listIcon:last-child {
  margin-left: 6px;

  :global {
    .spinner-container {
      display: flex;
      align-items: center;

      .spinner {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.listItemNavLink {
  color: variables.$color-grey-inactive;

  &:global(.active) {
    color: variables.$color-orange-1;
    font-weight: bold;
    text-decoration: underline;
  }
}

.listItemIcon {
  color: variables.$color-grey-inactive;
}
