@use "../../../style/variables";

.subheader {
  display: flex;
  align-items: center;
  gap: variables.$efile-current-return-filing-sub-gap-value;
  color: variables.$color-dark-3;
  font-size: 12px;

  input:disabled {
    cursor: default;
  }

  .subheaderInput {
    margin-right: 24px;
    font-size: 14px;
  }

  .subheaderInputStateSubmissionId {
    @extend .subheaderInput;

    :global {
      .a-input-text {
        width: 175px;
      }
    }
  }
}
