@use '../../../../style/variables';

$data-items-buttons-height: 34px;

$list-label-margin: 1rem;
$list-label-line-height: 1.125rem;
$list-label-height-with-margins: 57px;
$grid-error: 7px;

.dataItemsView {
  height: 100%;
}

.dataItemsButtons {
  text-align: right;

  .refreshDataButton {
    margin-left: 10px;
  }
}

.dataTypes {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  height: 100%;

  :global {
    .spinner-container--small {
      position: relative;
      height: 30px;
      text-align: left;
    }
  }
}

.gridContainer {
  flex: 3.5;
  display: flex;
  min-width: 300px;
  flex-direction: column;
  margin-bottom: calc(#{$list-label-line-height} + #{$grid-error});

  :global {
    .ag-theme-bootstrap {
      .ag-menu-separator-cell::after {
        display: block;
        border-top: solid 1px;
        border-top-color: #babfc7;
      }
    }
  }
}

.dataModelsAndDatasets {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 300px;

  .datasetsContainer {
    position: relative;
    height: 100%;

    :global {
      .a-list {
        position: absolute;
        width: 100%;
        height: calc(100% - #{$list-label-height-with-margins});
      }
    }
  }
}

.dataModelSelect {
  margin-bottom: 12px;
  padding: 0 2px; /* horizontal padding ensures that focus outline will be correctly displayed */
}

.listLabel {
  margin-bottom: $list-label-margin;
  color: variables.$color-dark-3;
  font-size: 0.75rem;
  line-height: $list-label-line-height;
}
