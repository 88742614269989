@use '../../../style/variables';

.modal {
  :global {
    .modal-body {
      display: flex;
      max-height: 80vh;
    }

    .modal-footer {
      display: none;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.modelParams {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.75rem 5rem;
}

.includeExpressions {
  margin-top: 2rem;
  padding-left: 1rem;

  :global {
    .a-checkbox-text {
      font-size: 100%;
    }
  }
}

.jurisdictionsLabel {
  margin: 2rem 0 0.75rem;
}

.alertIcon {
  color: variables.$color-orange-1;
  text-align: center;

  :global {
    .icon-size-16 {
      font-size: 130px;
    }
  }
}

.jurisdictionsList {
  position: relative;
  border: 1px solid variables.$color-silver;
  padding: 0 1rem 0 3rem;

  .checkboxIndeterminate {
    :global {
      .a-checkbox-input {
        opacity: 100;
      }
    }
  }

  .listItemHeader {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }

  .listItemLabel {
    margin-left: 0.5rem;
  }

  .listItemLabelDisabled {
    color: variables.$color-light-grey;
  }

  .listItemHeaderClickable {
    cursor: pointer;

    &:hover {
      .chevronIcon {
        color: variables.$color-silver-chalice;
      }

      .listItemLabel {
        color: variables.$color-silver-chalice;
      }
    }
  }

  .separator {
    position: absolute;
    right: 0;
    width: 2000px;
    height: 1px;
    background-color: variables.$color-silver;
    z-index: 1;
  }

  .listCollapse {
    padding: 1rem 0 1rem 4rem;
  }
}

.buttons {
  margin-top: 1.5rem;
}

.progressInfo {
  color: variables.$color-orange-1;
  font-size: 1.1rem;
  font-weight: bold;
}

.warningMessage {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  height: calc(80vh - 40px);
}

.warningHeader {
  margin: 0;
  font-size: 1.5rem;
  text-align: center;
}

.warningDetails {
  padding: 1em;
}
