@use '../../../../style/_variables.scss';

.overridableFormCheckboxWrapper {
  display: inline-flex;
  margin: 0;
}

.overridableCheckbox {
  display: inline-block;
  position: relative;
  margin: 0;
  vertical-align: text-bottom;
  line-height: 0;
}

.overridableCheckboxInput {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 0;
  opacity: 1;

  &:focus {
    outline: 1px solid variables.$color-orange-1;
  }
}

.overridableCheckboxInner {
  display: block;
  position: relative;
  top: 50%;
  width: 1rem;
  height: 1rem;
  border: 1px solid variables.$color-ghost;
  border-radius: 0.125rem;
  background-color: variables.$color-white;
  transform: translateY(-50%);
}

.overridableCheckboxText {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 0.625rem;
  transform: translate(-50%, -50%);
}

.overriddenCheckboxText {
  color: variables.$color-red;
}
