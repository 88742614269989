@use '../../style/variables';

:global {
  .subcategory-header {
    font-weight: 700;
  }

  .generic-category-grid .ag-row.subcategory-header-hidden {
    border-width: 0;
  }
}

.gridOptions {
  display: flex;
}

.suppressZerosCheckbox {
  width: 7.5rem;
  padding: 0.5em 0 0.5em 0.3em;

  span:nth-child(2) {
    color: variables.$color-black;
    font-size: variables.$regular-font-size;
  }
}

.showCategorySubtotalsCheckbox {
  @extend .suppressZerosCheckbox;

  width: 11rem;
}

.componentDetailCheckbox {
  @extend .suppressZerosCheckbox;

  width: 11rem;
}
