@use '../../../../style/variables';

.filingHeader {
  padding-left: 1rem;
  font-size: 16px;
  gap: 15%;
}

.jurisdiction {
  text-transform: capitalize;
}

.contextCol {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stepTabHeaderContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid variables.$color-alto;
  border-radius: variables.$border-radius-size-select;
  padding: 20px 32px;
  background-color: variables.$color-white;
  justify-content: center;
  gap: variables.$efile-current-return-filing-sub-gap-value;
}

.headerTitle {
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2.25rem;
}

.contextCol,
.pastSubmissionsColumn {
  flex: 1;
  max-width: 30%;
}

.pastSubmissionsColumn label {
  white-space: nowrap;
}
