.formListDropdown {
  width: 550px;
  margin-right: 20px;
  justify-content: center;

  label {
    display: inline;
  }
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.row {
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
  margin-bottom: 20px;

  :global {
    .add-button-column .add-button {
      margin-bottom: 0;
    }
  }
}
