@use '../../../style/variables';

.run-custom-report {
  .global-context {
    margin-bottom: 20px;

    .global-context-dropdown {
      margin-right: 10px;

      .a-form-label {
        margin-bottom: 10px;
      }
    }

    .custom-report-param-select {
      min-width: 200px;
    }

    .a-input-sm {
      margin: 0 10px 10px 0;

      input {
        border-style: none;
        background-color: variables.$color-concrete;
      }
    }
  }
}
