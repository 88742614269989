.gridContainer {
  display: flex;
  flex-direction: column;
}

.grid {
  flex: 1;
}

.links {
  max-height: 200px;
  overflow: scroll;
}

.alignCenter {
  text-align: center;
}

.actionMenuRow {
  position: relative;
  justify-content: flex-end;
  padding: 0 10px;
}
