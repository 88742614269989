@use '../../../style/variables';

.stickyFooter {
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  left: variables.$slide-in-width;
  height: 3.75rem;
  justify-content: space-between;
  border-top: 1px solid variables.$color-alto;
  padding: 0.8125rem 2rem;
  background-color: variables.$color-white;

  button {
    margin: 0; /* resetting the margins to 0 because of SLT-10526 */
    text-transform: uppercase;
  }
}
