@use '../../../style/variables';

.validationProcessRunningContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid variables.$color-alto;
  border-radius: variables.$border-radius-size-select;
  padding-bottom: 158px;
  background-color: variables.$color-white;
  align-items: center;

  .imageContainer {
    padding-top: 32px;
    padding-bottom: 40px;
  }

  .header {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 36px;
  }
}
