.formListDropdown {
  width: 550px;
  margin-right: 20px;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.row {
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
  margin-bottom: 20px;

  :global {
    .add-button-column .add-button {
      margin-bottom: 0;
    }
  }
}

.editSpinner {
  :global {
    .spinner {
      width: 30px;
      height: 30px;
      margin-top: 38px;
    }

    .spinner-container {
      width: 65px;
    }
  }
}
