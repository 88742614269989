@use '../../../../style/variables';

.stepTabRightHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  border-radius: variables.$border-radius-size-select;
  padding: 2rem;
  background-color: variables.$color-pwc-appkit-warning-background;
}

.stepTabRightHeaderNoErrors {
  background-color: variables.$color-pwc-appkit-success-background;
}

.stepTabRightHeaderFailed {
  background-color: variables.$color-pwc-appkit-failed-background;
}

.stepTabRightHeaderContent {
  display: flex;
  align-items: center;
}

.stepTabRightHeaderText {
  margin-right: 15px;
  color: variables.$color-dark-3;
  font-size: 16px;
}
