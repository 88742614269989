@use '../style/variables';

.formListDropdown {
  width: 35%;
  margin-right: 20px;
  justify-content: center;

  label {
    display: inline;
  }
}

.memberListDropdown {
  width: 250px;
  margin-right: 10px;
  justify-content: center;

  label {
    display: inline;
  }
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.tableHeaderText {
  width: 850px;
  margin: 0 auto;
  font-size: 0.9rem;
  text-align: center;
}

.disableLink {
  pointer-events: none;
  color: variables.$text-color;
}
