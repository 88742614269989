@use "variables";

.a-footer {
  margin: variables.$footer-margin 0;
  line-height: variables.$footer-line-height;
  text-align: center;

  /*
    It's important to use margins so the page does not scroll
    if on some page we have components with bottom margin.

    This way the margins overlap and the footer stays on the bottom
    and it's not being pushed down.
    It would be pushed down if we used `height` property
    and it would cause the page to scroll.
  */
}
