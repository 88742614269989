.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1em;
}

.datasetsDropdown {
  :global {
    .a-form-label {
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

.gridColumn {
  min-height: 310px;
  margin-bottom: 16px;
}
