.groupSelectionModal {
  :global {
    .ag-grid-custom {
      height: 100%;
      min-height: 500px;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
  }
}
