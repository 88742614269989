@use '../../style/_variables.scss';

.formSection {
  &::after {
    content: '';
    width: 100%;
    margin: 0 20px;
    border-bottom: 1px solid variables.$color-border-grey;
    padding-top: 10px;
  }
}

.checkboxesGroupWithInlineLabel {
  display: grid;
  grid-template-columns: 115px 1fr;
  align-items: center;
  width: 100%;

  :global {
    .a-form-label {
      margin-top: 9px;
      align-self: flex-start;
      text-transform: capitalize;
    }
  }
}

.verticalFilingAttributesGrid {
  display: grid;
  height: 280px;
  grid-template-columns: 110px 170px;
  margin: 0;
  grid-template-areas:
    'filing-attributes-group-1 filing-attributes-group-2'
    '. filing-attributes-group-3';
}

.verticalFilingAttributesGridWider {
  display: grid;
  grid-template-columns: 110px 150px 150px;
  margin: 0;
  grid-template-areas:
    'filing-attributes-group-1 filing-attributes-group-2 filing-attributes-group-3'
    'filing-attributes-group-1 filing-attributes-group-4 filing-attributes-group-3';
}

.filingAttributesGroup1 {
  grid-area: filing-attributes-group-1;
}

.filingAttributesGroup2 {
  grid-area: filing-attributes-group-2;
}

.filingAttributesGroup3 {
  grid-area: filing-attributes-group-3;
}

.filingAttributesGroup4 {
  grid-area: filing-attributes-group-4;
}

.verticalCheckboxesGroup {
  margin-left: 6px;
}

.checkboxesGroup {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  align-items: center;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;

  &.compact {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    overflow: default;
  }

  & > div {
    margin: 9px 9px 9px 0;
  }

  :global {
    .a-form-label {
      margin: 9px 0;
    }

    .a-checkbox-wrapper {
      display: flex;
    }

    .a-checkbox-text {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.fieldWithInlineLabel {
  :global {
    .a-input-sm {
      display: grid;
      grid-template-columns: 115px auto;
      align-items: center;

      .a-form-error,
      .invalid-feedback {
        margin: 0.625rem 0 0;
        grid-column-start: 2;
        grid-row-start: 2;
      }
    }

    .a-form-label {
      width: auto;
      margin: 7px 0;
    }
  }
}

.dropdownWithInlineLabel {
  display: grid;
  grid-template-columns: 115px auto;
  align-items: center;

  :global {
    .a-form-label {
      margin: 0;
    }
  }
}

.radioButtonsGroup {
  display: flex;

  label {
    padding: 0 10px;
  }

  :first-child {
    padding-left: 0;
  }

  :last-child {
    padding-right: 0;
  }
}

.sdk-custom-input-tooltip-content {
  color: variables.$color-dark-1;
}

.filingAttributesInvalidFeedback {
  display: block;
  margin: 0 0 0.625rem;
  grid-column-start: 2;
  color: variables.$color-pwc-appkit-red;
  font-size: 0.75rem;

  &.vertical {
    grid-column-start: 1;
  }
}
