@use '../../../style/_variables.scss';

.taxRatesContentWrapper {
  justify-content: center;

  :global {
    .ag-theme-bootstrap {
      .ag-cell-value {
        border-left: 1px solid variables.$color-mercury;
      }
    }
  }
}

.taxRatesBottomContainer {
  display: flex;
}

.taxRatesControlsContainer {
  min-width: 325px;
  margin-right: 25px;

  input[type='number'] {
    appearance: textfield;
    text-align: right;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
  }
}

.taxRatesRangeContainer {
  display: flex;
  justify-content: space-between;
}

.taxRatesExclusionContainer {
  display: flex;
}

.taxRatesFormInput {
  width: 150px;
  margin-bottom: 5px;
}

.taxRatesFormContainer {
  display: flex;
  padding-bottom: 15px;
}

.taxRatesFormContainer > div {
  margin-right: 5px;
}

.taxRatesCommonCalcContainer {
  width: 450px;
  overflow-x: auto;
}

.taxRatesCalcGroup {
  display: flex;
  margin: 0 10px 10px 10px;
  flex-flow: column wrap;
}

.taxRatesScrollContainer {
  overflow-x: auto;

  :global {
    .a-form-label {
      width: 250px;
      margin-bottom: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.taxRatesCalcPreferencesContainer {
  width: 15vw;
}

.taxRatesWrapper {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-auto-flow: column;
}
