@use '../../../../style/variables';

$downtrend-icon-color: variables.$color-orange-1;

.checkboxIcon {
  left: -5px;
}

.downtrendIcon {
  color: $downtrend-icon-color;

  /* conflict: stylelint wants a new line after a comma but prettier doesn't
  using prettier-ignore to keep the new lines
  prettier-ignore */
  text-shadow: -1px 0 $downtrend-icon-color, 0 1px $downtrend-icon-color,
    1px 0 $downtrend-icon-color, 0 -1px $downtrend-icon-color;
}
