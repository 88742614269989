.dataModelsContainer {
  :global {
    .a-checkbox-wrapper {
      margin-right: 5px;
    }
  }
}

.dataModelsDropdown {
  :global {
    .a-form-label {
      font-size: 1rem;
    }
  }
}

.datasetsHeader {
  padding-bottom: 10px;
}

.labelCol {
  margin-top: auto;
}

.dataItemsGrid {
  flex: 1;
  margin-top: 10px;
}

.gridContainer {
  display: flex;
  flex-direction: column;
}

.centeredCheckbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.renderAdditionalHeaderElementsContainer {
  display: flex;

  > .renderAdditionalHeaderElement:not(:last-child) {
    margin-right: 40px;
  }
}

.checkboxesGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dataModelsForm > * {
  margin-bottom: 1rem;
}
