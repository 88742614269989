.edit-reports {
  .action-buttons-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
}

.custom-reports-slidein {
  box-sizing: border-box;
  padding-bottom: 160px;

  .custom-reports-slide-in-footer .buttons-row {
    display: flex;
    width: 100%;
    margin-top: 0.5rem;

    button {
      flex: 1;
      margin: 0 0.25rem;
    }
  }

  .edit-field-icon {
    padding-left: 5px;
    cursor: pointer;
  }
}

.test-query-modal.modal-dialog {
  width: calc(100vw - 2 * 160px);
  max-width: calc(100vw - 2 * 160px);

  .ag-theme-bootstrap {
    height: 400px;
  }
}

.edit-param-modal {
  .verify-query-btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .error-message {
      margin-right: 10px;
      color: #c52a1a;
      font-size: 0.8rem;
    }
  }
}
