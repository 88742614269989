@use '../../../../../style/variables';
@use '../../../../../style/mixins';

.informationDetailsContent {
  gap: 20px;

  :global {
    @include mixins.slide-in-panel;
  }
}

.informationDetails {
  > h4 {
    margin-bottom: 20px;
  }

  > h6 {
    color: variables.$color-gray;
  }

  > p {
    font-size: 16px;
  }
}
