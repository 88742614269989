@use '../../style/variables';
@use '../../style/mixins';

.panel {
  :global {
    @include mixins.slide-in-panel;

    .slide-pane__content {
      padding: 32px;
    }

    .slide-pane__title {
      width: 125px;
    }
  }
}

.panelWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid variables.$color-light-grey;
  border-radius: 3px;
  padding: 32px;
}

.text {
  margin: 18px 20px;
  font-size: 18px;
  text-align: center;
}

.button {
  margin: 18px 0;
  text-transform: uppercase;
}

.panelItemWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 18px 0;
}

.downloadButtonWrapper {
  display: flex;
  flex-direction: column;
  margin: 18px 0;
}

.downloadButton {
  margin: 0;
  text-transform: uppercase;
}

.illustration {
  width: 200px;
  height: 200px;
}

.pdfGenerationDateText {
  color: variables.$color-pwc-appkit-grey-darker;
  font-size: 14px;
}

.radioOptionWarpper {
  display: flex;
  font-weight: bold;
  justify-content: space-evenly;
}
