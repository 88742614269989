@use '../../style/variables';

.spinnerButton {
  :global {
    .spinner-container {
      display: flex;
      margin-left: 10px;
    }
  }
}

.gridContainer :global(.ag-cell-value).redFlag {
  color: variables.$color-pwc-appkit-red;
}

.gridContainer {
  :global {
    .ag-grid-custom .ag-theme-bootstrap .ag-center-cols-viewport .ag-row.ag-row-even,
    .ag-grid-custom .ag-theme-bootstrap .ag-center-cols-viewport .ag-row.ag-row-odd {
      &.ag-row-focus {
        background-color: variables.$color-barley-white;
      }

      &.ag-row-hover {
        background-color: variables.$color-wild-sand;
      }

      &.ag-row-selected {
        background-color: variables.$color-white;
      }

      &.ag-row-focus.ag-row-selected,
      &.ag-row-focus.ag-row-hover {
        background-color: variables.$color-barley-white;
      }

      --ag-selected-row-background-color: variables.$color-white;
    }
  }
}
