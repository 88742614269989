.transition-list-item {
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    transition: opacity 200ms ease-in;
    opacity: 1;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    transition: opacity 200ms ease-in;
    opacity: 0;
  }
}
