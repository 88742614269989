.session-warning-banner {
  z-index: 100000;
  position: sticky;
  top: 0;
  width: 100%;
}
.session-warning-banner .banner-button {
  text-transform: uppercase;
  margin-left: 10px;
}

.session-warning-overlay {
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
}
.session-warning-overlay .overlay-info-text {
  font-size: 20px;
  text-align: center;
}
.session-warning-overlay .overlay-button-wrapper {
  margin-top: 1rem;
  text-align: center;
}