@use "../style/variables";

.notificationIconContainer {
  position: relative;
  margin-right: 14.5px;
  cursor: pointer;
  user-select: none;

  :global {
    .a-avatar.a-avatar-default.a-avatar-icon .appkiticon {
      font-size: 1.125rem;
    }
  }

  &:hover {
    color: variables.$color-orange-1;

    :global {
      .a-avatar.a-avatar-default.a-avatar-icon .appkiticon {
        color: variables.$color-orange-1;
      }
    }
  }

  .notificationAvatar {
    border-radius: 100%;
    background-color: variables.$color-white;

    &.active {
      border: 2px solid variables.$color-silver-chalice;
      background-color: variables.$color-concrete;
    }
  }

  .notificationCount {
    position: absolute;
    top: 2px;
    left: 15px;
    padding: 0 6px;
    font-size: 10px;
  }
}
