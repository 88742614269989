.navigation-tabs-wrapper {
  display: flex;

  .tabs-wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 15px;

    .tabs-container {
      width: 100%;
      float: left;
    }
  }

  .global-context {
    flex-wrap: nowrap;
    align-items: start;

    .action-menu {
      margin-top: 4px;
    }

    .Select-multi-value-wrapper {
      display: flex;
    }
  }
}
