@use "../../../style/variables";

.card-container-link {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.card-container {
  min-width: 280px;
}

.element-card {
  display: flex;
  padding: 30px 20px 10px;
  color: variables.$color-mine-shaft;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    box-shadow: variables.$box-shadow-card-hover;
    transform: translateY(-0.3rem);
    transition: 0.3s ease;
  }

  .small-card-format-for-bottom-element {
    max-height: 115px;
    padding: 0;

    .description {
      margin-top: -15px;
      margin-bottom: -10px;
    }
  }

  .card-element {
    display: flex;
    text-align: center;
    justify-content: center;

    .icon {
      margin-bottom: 30px;
    }

    .title {
      display: flex;
      align-items: center;
      height: 50px;
      margin-bottom: 15px;
      font-size: 16px;
      line-height: 20px;

      .title-with-icon {
        margin: 0;
        padding: 0;
      }
    }

    .description {
      display: flex;
      align-items: center;
      height: 50px;
      font-size: 11px;
      line-height: 15px;
    }
  }
}

.locked {
  pointer-events: none;
  opacity: 0.5;
}
