@use "../../../../style/variables";

.pageWrapper {
  display: flex;
  flex-direction: column;
  padding: 24px 64px 24px 32px;
  gap: 20px;
  flex: 1;

  > * {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.tabs {
  border: 1px solid variables.$color-alto;
  border-radius: variables.$border-radius-size-select;
  padding: 24px 50px;
  background-color: variables.$color-white;
}

.returnInformation {
  display: flex;
  flex-direction: column;
  border: 1px solid variables.$color-alto;
  border-radius: variables.$border-radius-size-select;
  padding: 20px 32px;
  background-color: variables.$color-white;
}

.returnAndTransmitterGrid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 4px;
  align-items: flex-end;
}

.additionalInformation {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.informationTitle {
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2.25rem;
}

.additionalInformationGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4px;
  margin-top: 10px;
}

.stepsGrid {
  width: unset;
  min-height: 400px;
}

.rerunButtonContainer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 0.5rem;
}
