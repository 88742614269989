@use '../../../../style/variables';

.validationPanel {
  .validationLastRun {
    color: variables.$color-gray;
    font-size: 14px;
    line-height: 21px;
  }

  .validationTable {
    height: 40vh;
  }

  :global {
    .ag-theme-bootstrap {
      .ag-header-container {
        .ag-header-cell {
          .ag-cell-label-container {
            .ag-header-cell-label {
              justify-content: left;

              .ag-header-cell-text {
                font-size: 14px;
              }
            }
          }
        }
      }

      .ag-row {
        .ag-cell-value {
          font-size: 14px;

          .a-btn {
            margin: 0;
            padding: 0;
          }

          a {
            font-size: 0.75rem;
            font-weight: 500;
          }
        }
      }
    }
  }
}
