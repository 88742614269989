@use '../style/variables';

.navigationButtonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;

  .navigationButton {
    margin-left: 1rem;
  }
}

.nextButtonWarning {
  margin-top: 0.4em;
  color: variables.$color-red;
  font-size: 0.9em;
  font-style: italic;
  text-align: right;
}

.inline {
  display: flex;
  align-items: center;
  height: 3em;
}

.authorizationWarning {
  margin-top: 0.4em;
  color: variables.$color-grey-inactive;
  font-size: 0.9em;
  font-style: italic;
  text-align: left;
}

.returnContext {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 0;
  margin: 1rem 0;

  :global {
    .sdk-custom-select-global-context-dropdown {
      width: 220px;
      margin-right: 10px;
      margin-bottom: 0;
      align-items: center;
    }
  }
}

.ttiBinderField {
  display: flex;
  align-items: center;
  margin-right: 10px;

  :global {
    .a-input-text {
      width: 240px;
      height: 28px;
      font-size: 12px;
    }
  }
}

.caseIdField {
  display: flex;
  align-items: center;
  margin-right: 10px;

  :global {
    .a-form-label {
      width: 80px;
      margin: 0 5px 0 0;
    }

    .a-input-text {
      width: 90px;
      height: 28px;
      font-size: 12px;
    }
  }
}

.whiteBox {
  border-radius: 3px;
  box-shadow: variables.$box-shadow-card;
  padding: 16px;
  background: variables.$color-white;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;

  .inline {
    display: flex;

    .lastUpdateDate {
      margin-left: 20px;
    }
  }
}

.overallStatus {
  margin-bottom: 10px;
  text-align: center;

  h6 {
    margin-bottom: 0;
  }
}

.wizardSteps {
  margin: 2rem 0 1rem 0;

  :global {
    .progress-text {
      padding: 1rem 2rem 0.5rem 2rem;
    }

    .progress-step {
      cursor: pointer;
    }

    .step-disabled {
      cursor: not-allowed;
    }

    .current {
      cursor: inherit;
    }
  }
}

.stepName {
  margin-top: 8px;
}

.stepSection {
  margin-top: 32px;
}

.stepRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .stepInfo {
    display: flex;
    align-items: center;
  }

  .status {
    display: flex;
    align-items: center;
    width: 350px;
  }

  .eFileRunActionButton {
    margin-right: 16px;
  }

  .infoMessage {
    margin: 0 8px 0 8px;
  }
}

.subStepBox {
  margin-bottom: 20px;
}

.actionBox {
  margin-bottom: 10px;
}

.stepStatus {
  display: flex;
}

.capitalizeStatus {
  display: inline-block;
  margin-left: 4px;
}

.capitalizeStatus::first-letter {
  text-transform: capitalize;
}

.actionTitle {
  margin-bottom: 0;
}

.align {
  display: flex;
  align-items: center;
}

.submissionIdField {
  display: flex;
  align-items: center;

  :global {
    .a-form-label {
      width: 80px;
      margin: 0 10px 0 0;
    }

    .a-input-text {
      width: 100px;
      height: 28px;
      font-size: 12px;
    }
  }
}

.ttiSubmissionIdField {
  display: flex;
  align-items: center;
  margin-right: 20px;

  :global {
    .sdk-custom-select-global-context-dropdown {
      width: 350px;
    }

    .a-form-label {
      margin: 0 10px 0 0;
    }

    .a-input-text {
      width: 200px;
      height: 28px;
    }
  }
}

.wizardStepAdditionalInfo {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  justify-content: center;

  .transmitStatusTable {
    td {
      min-width: 100px;
      padding-right: 30px;
    }
  }
}

.transmitStatusErrors {
  margin: 20px auto;
}

.signatureDeclaration {
  margin: 20px 0;
}

.debugInfoIcon {
  margin-left: 5px;
}

.consolidationDropdown {
  width: 600px;
  margin-top: 30px;
}

.federalProformaSubmissionId {
  display: flex;
  align-items: center;
  min-height: inherit;

  :global {
    .a-form-label {
      margin: 0 10px 0;
    }

    .a-input-text {
      height: 28px;
    }
  }
}

.nyCaseFiledEntitiesInfoCheckbox {
  margin-right: 30px;
}

.federalAttachmentDropdown {
  :global {
    .sdk-custom-select-global-context-dropdown {
      width: 375px;
      margin-left: 20px;
    }
  }
}

.includeFederal {
  min-height: 60px;
}

.federalProformaSubmissionIdInput {
  position: relative;
  top: 15px;
  min-height: inherit;
}

.ttiBinderDropdown {
  :global {
    .global-context-dropdown-wrapper {
      width: 100%;
    }
  }
}

.userStep {
  margin-bottom: 2em;

  :global {
    h4 {
      color: variables.$color-orange-1;
    }
  }

  .collapsed {
    display: none;
  }

  .indentSubsteps {
    padding-left: 6em;
  }

  .userStepNutshell {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;

    :global {
      i {
        margin-left: 0.5em;
      }

      a {
        margin-right: 0.5em;
      }
    }
  }
}

.validationContainer {
  padding: 0 4em;
}

.runButtonWarning {
  display: flex;
  align-items: center;
  margin-left: 1em;
  color: variables.$color-red;
  font-size: 0.9em;
  font-style: italic;
  text-align: left;
}
