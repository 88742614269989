.fileFormatText {
  display: block;
}

.fileFormatTextIncorrect {
  color: red;
}

.zipFileContentsInstructions {
  display: block;
}

.zipFileStructureInstructionsText {
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
}

.zipFileNotes {
  display: block;
  margin-top: 10px;
}
