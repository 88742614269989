@use '../../../style/variables';

.splitPane {
  display: flex;
  width: calc(100vw - 30px);
  height: 100%;
  flex-direction: row;
  overflow: auto;
}

.splitPaneElement {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.separator {
  width: 5px;
  margin: 0 10px;
  background: variables.$color-silver-sand;
  transition: all 0.2s ease;
  cursor: col-resize;
}

.separator:hover {
  background: variables.$color-dark-1;
}

.dragging {
  width: 5px;
  margin: 0 10px;
  background: variables.$color-orange-1;
  cursor: col-resize;
}
