@use '../../style/variables';

.componentDetailCheckbox {
  margin: 0 10px 15px 0;

  :global {
    .a-checkbox-text {
      color: variables.$color-black;
      font-size: variables.$regular-font-size;
    }
  }
}
