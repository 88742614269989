.setupTaxReturnsContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 4px 32px 0 32px;
}

.setupTaxReturnsGridWrapper {
  flex: 1;

  :global {
    .ag-theme-bootstrap {
      .ag-row {
        .ag-cell-value {
          &.editable-dropdown-cell {
            padding-right: 10px;

            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 3px;
              bottom: 0;
              width: 6px;
              height: 6px;
              margin: auto 0;
              border: 1px solid;
              border-top: none;
              border-left: none;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
}

.filingGroupIdsContainer {
  text-overflow: ellipsis;
  overflow: hidden;
}

.datagridHeader {
  width: fit-content;
  margin-bottom: -25px;
  padding: 15px 0 0;
  font-size: 12px;
  z-index: 1;
}
