@use '../../style/variables';

.add-partnership-information {
  border-radius: 3px;
  box-shadow: variables.$box-shadow-card;
  padding: 8px;
  background: variables.$color-white;

  & + & {
    margin-top: 15px;
  }

  .add-partnership-information-field {
    margin-bottom: 1rem;
  }
}
