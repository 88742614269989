@use '../../style/mixins';
@use '../../style/variables';

.panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  :global {
    @include mixins.slide-in-panel;

    .slide-pane__content {
      padding: 0;
      background-color: variables.$color-concrete;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.tabs {
  padding: 24px 32px 0;
  background-color: variables.$color-white;
}

.control {
  padding: 24px 32px;
  background-color: variables.$color-white;

  & + & {
    padding-top: 0;
  }
}

.radioOptionWrapper {
  padding: 8px 0;

  & + & {
    margin: 0;
    padding: 0;
  }
}

.filters {
  padding: 6px 12px;
}

.filter {
  margin: 0; /* resetting the margins to 0 because of SLT-10526 */
  text-transform: uppercase;

  :global {
    .a-badge {
      line-height: 1.125rem;
    }
  }
}

.list {
  overflow: auto;
}

.listItem {
  display: flex;
  border-top: 1px solid variables.$color-alto;
  padding: 11px 16px 13px;
  background-color: variables.$color-white;

  &:last-child {
    border-bottom: 1px solid variables.$color-alto;
  }

  :global {
    .a-checkbox-text {
      font-weight: 500;
      line-height: 1.5rem;
    }
  }
}

.noResultsMessage {
  margin: 1rem 2rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.uploadPdfs {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 48px;
  padding: 64px 48px;
  background-color: variables.$color-white;
}

.uploadAttachmentInformation {
  font-weight: 500;
}

.uploadAttachmentsLink {
  width: 100%;
  text-decoration: none;

  /* nesting to boost specificity to override `height` */
  .uploadAttachmentButton {
    width: 100%;
    height: 4rem;
    margin: 0; /* resetting the margins to 0 because of SLT-10526 */
    text-transform: uppercase;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 7px 18px 1px variables.$color-opacity-black;
  padding: 13px 44px;
  background-color: variables.$color-white;

  button {
    margin: 0; /* resetting the margins to 0 because of SLT-10526 */
  }
}

.selectedItemsInformation {
  margin-right: 4rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.footerButton {
  text-transform: uppercase;

  & + & {
    margin-left: 13px;
  }
}
