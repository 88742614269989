@use '../../style/_variables.scss';

.pdfDocumentModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: variables.$slide-in-width;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
