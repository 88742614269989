@use '../../../../style/variables';

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  padding: 12px 16px;

  &.clickable {
    cursor: pointer;
  }
}

.cardHeaderTitleAndIcon {
  display: flex;
  align-items: center;
}

.alertIcon {
  margin: 0 16px 0 8px;
  color: variables.$color-pwc-appkit-yellow;
}

.newsIcon {
  margin: 0 16px 0 8px;
  color: variables.$color-grey-dark;
}

.cardHeaderTitleSection {
  display: flex;
  flex-direction: column;
}

.cardHeaderTitle {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
}

.cardHeaderSubtitle {
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

.cardHeaderActionsSection {
  display: flex;
}

.fileCountWrapper {
  border: 1px solid variables.$color-orange-1;
  border-radius: 14px;
  padding: 0 8px 2px;
}

.fileCountText,
.downloadText {
  color: variables.$color-orange-1;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.375rem;
  text-transform: uppercase;
}

.chevronIcon {
  margin-left: 16px;
  color: variables.$color-orange-1;
}

.cardCollapse {
  border-top: 1px solid variables.$color-alto;
  padding: 13px 16px 16px;
}

.attachmentLink {
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

.downloadIcon {
  color: variables.$color-orange-1;
  font-size: 16px;
  margin-inline-end: 13px;
}

.card {
  max-width: 500px;
  border: 1px solid variables.$color-alto;
  border-radius: 4px;

  &.inactive {
    border-color: variables.$color-concrete;
    background-color: variables.$color-concrete;
    color: variables.$color-pwc-appkit-grey-darker;

    .downloadIcon,
    .fileCountText,
    .downloadText {
      color: variables.$color-pwc-appkit-grey;
    }

    .cardHeader.clickable {
      cursor: auto;
    }
  }

  &.warning {
    border-color: variables.$color-pwc-appkit-yellow;
    background-color: rgba(variables.$color-pwc-appkit-yellow, 0.08);
  }

  &.buttonStyle {
    display: flex;
    justify-content: center;
    border-color: variables.$color-orange-1;
    color: variables.$color-orange-1;
    text-transform: uppercase;
  }
}
