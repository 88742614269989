@use '../style/variables';

.returnStatusTrackerDetailsHeaderGlobalContextValues {
  margin-left: -10px;
  color: variables.$color-pwc-appkit-grey-darker;
  line-height: 20px;

  :global {
    .a-form-label {
      color: variables.$color-pwc-appkit-grey-darker;
    }
  }
}

.removeMargin {
  margin-bottom: -5px;
}

.returnStatusTrackerDetailsCard {
  flex: 1 1;
  margin-bottom: 0;

  :global {
    .return-status-tracker-details-header {
      margin-bottom: 5px;
      padding: 0 20px 5px;
    }
  }
}

.statusVisualsHeader {
  display: flex;
  margin: 0 20px;
  font-weight: 500;
}

.statusVisualsHeaderTitle {
  margin-right: 0.5rem;
  margin-bottom: 0;
  color: variables.$color-dark-3;
}

.statusVisualsHeaderSubTitle {
  color: variables.$color-pwc-appkit-grey-darker;
  font-size: 12px;
}

.statusIcon {
  margin-right: 1rem;
  color: variables.$color-return-status-tracker-default-color;
}

.statusVisualsChartContainer {
  display: flex;
  flex-direction: row;
  margin: 0 20px 5px; /* 2px is to let shadow not be covered by search bar: SLT-15948 */
  padding: 0 2rem;

  :global {
    .not-started-visual-icon {
      color: variables.$color-return-status-tracker-not-started-color;
    }

    .in-progress-visual-icon {
      color: variables.$color-return-status-tracker-in-progress-color;
    }

    .complete-visual-icon {
      color: variables.$color-return-status-tracker-complete-color;
    }

    .not-started-visual {
      fill: variables.$color-return-status-tracker-not-started-color;
    }

    .in-progress-visual {
      fill: variables.$color-return-status-tracker-in-progress-color;
    }

    .complete-visual {
      fill: variables.$color-return-status-tracker-complete-color;
    }
  }
}

.statusVisualsChartContent {
  max-width: 120px;
  margin-right: 4rem;
  padding-right: 0;
  box-sizing: content-box;
}

.statusVisualsInfoTitleContainer {
  border-bottom: 2px solid #dedede;
  color: variables.$color-dark-3;
  font-weight: 500;
}

.statusRow {
  color: variables.$color-pwc-appkit-grey-darker;
  font-weight: 500;
  line-height: 24px;
}

.statusVisualsInfoTitleContainer,
.statusRow {
  :global {
    .col:nth-child(1) {
      padding-left: 0;
    }

    .col:nth-last-child(1) {
      padding-right: 0;
    }
  }
}

.dataGridContainer {
  padding: 0 20px;

  :global {
    .ag-theme-bootstrap {
      .ag-row {
        .ag-cell-value {
          &.editable-dropdown-cell {
            padding-right: 10px;

            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 3px;
              bottom: 0;
              width: 6px;
              height: 6px;
              margin: auto 0;
              border: 1px solid;
              border-top: none;
              border-left: none;
              transform: rotate(45deg);
            }
          }
        }
      }
    }

    .ag-grid-custom {
      .ag-theme-bootstrap:not(.ag-popup) {
        height: calc(100% - 30px);
        margin-top: -5px;
      }
    }
  }
}

.datagridHeader {
  display: flex;
  position: absolute;
  color: #2d2d2d;
  font-size: 16px;
  font-weight: 500;
  z-index: 10000;

  a {
    margin-left: 10px;
  }
}
