.formWrapper {
  height: 100%;
}

.formNavigationTabsWrapper {
  align-items: center;
}

.formTabsWrapper {
  min-width: 200px;
  flex: 1;
}

.formHeader {
  display: flex;
  justify-content: space-between;
  margin: 5px 0 10px;
  align-items: center;
  flex-wrap: wrap;
}

.pageSection {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 30px;
  margin: 0 0 2px;

  :global {
    .spinner-container--small {
      height: 20px;
    }
  }
}

.ofPages {
  font-size: 14px;
}

.pageDropdown {
  :global {
    .sdk-custom-select-global-context-dropdown {
      width: 90px;
      margin: 2px 5px 0 0;

      .global-context-dropdown-wrapper {
        width: 100%;
      }
    }
  }
}

.fieldsView {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.displayDropdown {
  width: 350px;
  height: 30px;
  margin: 0 5px;

  :global {
    .global-context-dropdown {
      font-size: 12px;
    }

    .spinner-container--small {
      margin: auto;
    }
  }
}

.dropdownsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 14px 20px;
}
