.ag-theme-bootstrap {
  /* menu */
  /* header and row */
  /* header */
  /* row */
  /* shadowed theme */
}
.ag-theme-bootstrap .ag-root {
  border: 0;
}
.ag-theme-bootstrap .ag-overlay-no-rows-wrapper {
  padding-top: 60px;
}
.ag-theme-bootstrap .ag-menu {
  background-color: #fff;
}
.ag-theme-bootstrap .ag-menu .ag-tab-header {
  background-color: #fff;
}
.ag-theme-bootstrap .ag-menu .ag-menu-option-active {
  background-color: #fff0cc;
}
.ag-theme-bootstrap .ag-header,
.ag-theme-bootstrap .ag-row {
  border-bottom: 1px solid #e6e6e6;
}
.ag-theme-bootstrap .ag-header-container {
  background-color: #e0e0e0;
}
.ag-theme-bootstrap .ag-header-container .ag-header-cell {
  padding: 4px;
}
.ag-theme-bootstrap .ag-header-container .ag-header-cell .ag-cell-label-container {
  flex-direction: row;
}
.ag-theme-bootstrap .ag-header-container .ag-header-cell .ag-cell-label-container .ag-header-cell-label {
  margin-left: 3px;
  overflow: visible;
}
.ag-theme-bootstrap .ag-header-container .ag-header-cell .ag-cell-label-container .ag-header-cell-label .ag-header-cell-text {
  color: #333;
  font-size: 12px;
  font-weight: bold;
  white-space: normal;
}
.ag-theme-bootstrap .ag-header-container .ag-header-cell .ag-cell-label-container .ag-header-cell-menu-button {
  margin-top: 0;
  order: 2;
}
@media (max-width: 768px) {
  .ag-theme-bootstrap .ag-header-container .ag-header-cell .ag-cell-label-container .ag-header-icon .ag-header-cell-menu-button {
    display: none;
  }
}
.ag-theme-bootstrap .ag-row.ag-row-even, .ag-theme-bootstrap .ag-row.ag-row-odd {
  background-color: #fff;
}
.ag-theme-bootstrap .ag-row.ag-row-even.ag-row-hover, .ag-theme-bootstrap .ag-row.ag-row-even.ag-row-focus, .ag-theme-bootstrap .ag-row.ag-row-odd.ag-row-hover, .ag-theme-bootstrap .ag-row.ag-row-odd.ag-row-focus {
  background-color: #fff0cc;
}
.ag-theme-bootstrap .ag-row:not(.ag-row-first) {
  border-width: 0 0 1px;
}
.ag-theme-bootstrap .ag-row .ag-cell-value {
  padding: 4px;
  color: #333;
  font-size: 12px;
  line-height: 1.35;
}
.ag-theme-bootstrap.ag-theme-bootstrap-shadowed .ag-row-even:not(.ag-row-selected) {
  background-color: #f2f2f2;
}
.ag-theme-bootstrap.ag-grid-with-scroll-pagination {
  display: flex;
  flex-direction: column;
}
.ag-theme-bootstrap .scroll-pagination {
  margin-top: 0.5rem;
  overflow-x: hidden;
}