@use '../../style/variables';

.gridHeader {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
}

.gridHeaderLabels {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 15px);
  margin-right: 3px;
  color: variables.$text-color;
  font-size: variables.$regular-font-size;
}

.displayText {
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dataItemNameText {
  text-overflow: ellipsis;
  overflow: hidden;
}
