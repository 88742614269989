.account-section {
  margin-bottom: 10px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.08);
  padding: 8px;
  background: #fff;

  .spinner-container {
    display: flex;
  }

  .spinner {
    width: 20px;
    height: 20px;
    margin: 0;
  }

  .a-form-label {
    margin: 3px 5px 4px 1px;
    white-space: nowrap;
  }
}
