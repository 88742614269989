.quickFilterBadgeGroupContainer {
  margin-bottom: -25px;
  padding: 15px 0 0;
}

.quickFilterBadgeGroupHeader {
  font-size: 12px;

  a {
    margin-left: 15px;
  }
}

.quickFilterBadgeWrapper {
  padding: 5px 0;
}

.quickFilterBadgeButtonWrapper {
  margin: 0;

  & + & {
    margin-left: 10px;
  }
}
