@use "./style/variables";

.clientSelectionPanel {
  margin-top: 3rem;
  box-shadow: 0 0.1875rem 0.4375rem 0.0625rem rgba(0, 0, 0, 0.16);
  padding: 1.5rem;
  background-color: variables.$color-white;
}

.clientSelectionLogo {
  margin-bottom: 1.5rem;
  text-align: center;
}

.clientSelectionHeader {
  text-align: center;
}

.clientSelectionFooter {
  display: flex;
  margin-top: 1rem;
  border-top: 1px solid variables.$color-alto;
  padding-top: 1rem;
  align-items: center;
  justify-content: flex-end;
}
