@use '../../../../../style/variables';

.textContainer {
  width: 496px;
  color: #2d2d2d;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
}

.formikColumn {
  flex: 1;
  margin-right: 10px;
}

.trRejectionsMessagesColumn {
  flex: 1;
  margin-left: 10px;
}

.contentTitle {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.875rem;
}

.filingRow > * {
  margin: 10px 30px;
}

.returnNotRequired {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 84px;
  border: 1px solid #dedede;
  border-radius: variables.$border-radius-size-select;
  padding-left: 1rem;
  background-color: variables.$color-white;
}

.returnRequirementsContainer {
  display: flex;
  margin-top: 0.5rem;
  justify-content: space-between;
  gap: 20px;
}
