@use '../style/variables';

.returnInformationPanelContainer {
  display: flex;
  padding: 1.875rem 1rem 0 1rem;
  justify-content: space-between;
  flex-direction: column;
}

.returnInformationPanel {
  border: 1px solid variables.$color-alto;
  border-radius: 3px;
  padding: 1.875rem;
  background-color: variables.$color-white;
  font-size: 0.875rem;
}

.returnInformationPanelHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.returnStatusLabel {
  margin-right: 0.5rem;
  margin-bottom: 0;
  font-weight: 500;
}

.returnHandlerLabel {
  margin: 0.38rem 0.5rem 0.5rem 0;
  font-weight: 500;
}

.returnInformationLabel {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.returnInformationField {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.returnStatusFieldSDK {
  display: flex;
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
}

.returnInformationGridPanel {
  display: flex;
  height: 100%;
  margin: 1.875rem 1rem 0 1rem;
  border: 1px solid variables.$color-alto;
  border-radius: 3px;
  padding: 0 1.875rem 2rem;
  background-color: variables.$color-white;
  flex-direction: column;
}

.gridButtonsWrapper {
  margin: 1.5rem 0;
}

.gridButton {
  margin: 0;

  & + & {
    margin-left: 10px;
  }
}

.gridWrapper {
  flex: 1;

  :global {
    .ag-floating-top {
      /* because pinned row in this case only have one line
      so set overflow-y as hidden to fix appkit tooltip issue */
      overflow-y: hidden !important;
    }

    .ag-layout-normal {
      .ag-center-cols-clipper,
      .ag-center-cols-container {
        min-height: 100px;
      }
    }
  }
}

.footer {
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
}

.downloadIcon {
  margin-right: 8px;
}

.pdfGenerationDate {
  display: flex;
  align-items: center;
  color: variables.$color-pwc-appkit-grey-darker;
  font-size: 12px;
}

.pdfGenerationStatusSpinner {
  max-height: 16px;
  padding-left: 5px;
}

.primaryFormInfoIcon {
  position: absolute;
  top: 1px;
  left: 10px;
  color: variables.$color-pwc-appkit-grey-darker;
}

.returnStatusStyle {
  width: 200px;
}

.yesButton {
  width: 60px;
  height: 18px;
  margin-left: 10px;
  border-radius: 9px;
  background-color: variables.$color-fern;
  color: variables.$color-white;
  text-align: center;
}

.notAvailContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.efileSupportInfoIcon {
  margin-left: 5px;
  color: variables.$color-pwc-appkit-grey-darker;
}

.filingInformationContainer {
  height: 100%;
  min-height: 50px;
  margin-top: -1.875rem;
  margin-right: 0;
  margin-left: 0;
  background-color: variables.$color-pwc-appkit-grey-darker;
}

.filingInformationWrapper {
  display: flex;
  height: 100%;
  margin: 1rem 1.875rem;
  align-items: center;
  flex-direction: row;
}

.filingInformationContent {
  margin-left: 10px;
  color: variables.$color-white;
  font-size: 14px;
}

.filingInformationContentOverflow {
  white-space: nowrap;
  overflow: hidden;
}

.dots {
  color: variables.$color-white;
}

.dots:hover {
  cursor: pointer;
}

.filingInformationIcon {
  color: variables.$color-white;
}

.returnMaintenancePageContainer {
  width: calc(
    100vw - 400px - 30px
  ); /* 400px is the width of the left navigation panel, 30px is the margin between the left and right padding of container-fluid */
}

.tooltipContentWrapper {
  max-width: 600px;
  color: variables.$color-dark-3;
  font-size: 12px;
}
