@use '../../../../../style/variables';
@use '../../../../../style/mixins';

.downloadsPanelContent {
  gap: 20px;

  :global {
    @include mixins.slide-in-panel;
  }
}

.loadingContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid variables.$color-alto;
  border-radius: 4px;
  padding: 64px 32px;
}

.loadingSpinner {
  position: relative;
  height: unset;
  opacity: 1;
}

.fetchingInformation {
  margin-top: 2rem;
}

.topContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.downloadCardsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 10px;
}

.bottomContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.noFilesToDownloadText {
  color: variables.$color-pwc-appkit-grey-darker;
}
