@use '../../../style/variables';

.selectInputWrapper {
  margin-bottom: 3px;
  border: none;
  border-radius: 0.125rem;
  box-shadow: variables.$box-shadow-appkit-field;
}

.selectInput {
  width: 100%;
  padding: 10px;
}

.selectItemContent {
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  span {
    line-height: 30px;
  }
}

.virtualizedListContainer {
  display: flex;
  width: 200px;
  height: 280px;
  flex-direction: column;
}

.spinnerWrapper {
  flex: 1;
}

.noRowsWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.rowHighlighted {
  background: variables.$color-orange-1;
  color: variables.$color-white;
}

.rowSelected {
  background: variables.$color-orange-2;
  color: variables.$color-white;
}
