@use '../../../../../../style/variables';

.radioWrapper {
  box-sizing: border-box;
  width: 496px;
  height: 88px;
  margin-bottom: 10px;
  border: 1px solid variables.$color-alto;
  border-radius: 4px;
  padding: 10px 16px;

  &:global(.a-radio-wrapper-checked) {
    height: auto;
    border: 1px solid variables.$color-orange-1;
    background-color: rgba(208, 74, 2, 0.08);
  }

  :global {
    .a-radio {
      padding-left: 10px;
    }

    .a-radio-text {
      flex: 1;
    }
  }
}

.iconContainer {
  display: flex;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 19px;
}

.cardHeaderTitleSection {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cardHeaderTitle {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
}

.cardHeaderSubtitle {
  font-size: 0.875rem;
  line-height: 1.3125rem;
}

.cardHeaderSelect {
  :global {
    .a-input-text {
      background-color: variables.$color-white;
    }
  }
}

.cardHeaderSelectActive {
  padding-top: 5px;
}

.warningIcon {
  margin-left: 16px;
  color: variables.$color-yellow;
  visibility: hidden;
}

.federalIdMissing:global(.a-radio-wrapper-checked) {
  border-color: variables.$color-pwc-appkit-yellow;
  background-color: rgba(255, 191, 31, 0.08);

  .warningIcon {
    visibility: visible;
  }
}
