@use '../../../style/variables';

.addEditReturnPanelTooltip {
  // !important is necessary because <Tooltip> component hard-codes z-index to 9999 with inline style property
  z-index: 99999 !important;
}

.panel {
  :global {
    .slide-pane__header {
      flex-direction: row-reverse; // reversing to switch places of the title and icon
      border-bottom: 1px solid variables.$color-light-grey;
      padding: 0 20px;
      background: variables.$color-white;
    }

    .slide-pane__close {
      padding-left: 0;
    }

    .slide-pane__title-wrapper {
      text-align: left;
    }

    .slide-pane__content {
      display: flex;
      flex-direction: column;
      padding: 0;
      overflow: hidden;
    }

    .navigation-tabs-wrapper {
      margin-top: 32px;
      padding: 0 20px;
    }
  }

  .sectionTitle {
    font-weight: 500;
  }

  .fields {
    grid-row-gap: 20px;
    display: grid;
    height: 100%;
    padding: 15px 20px;
    padding-bottom: 60px;
    overflow: auto;
    grid-auto-rows: max-content;
  }

  .returnOptionsTabFields {
    @extend .fields;
  }

  .consolOptionsTabHeader {
    padding: 15px 20px;
  }

  .consolOptionsTabFields {
    @extend .fields;

    padding: 0 20px;

    :global {
      .filing-group-list {
        height: 400px;
        background-color: #f2f2f2;
        overflow: auto;

        .no-filing-group-container {
          display: flex;
          height: 480px;

          .no-filing-group-body {
            width: 250px;
            margin: auto;
            text-align: center;

            button {
              margin-left: 0;
            }

            .no-filing-group-title {
              color: #000;
              font-size: 16px;
            }

            .no-filing-group-desc {
              font-size: 14px;
            }
          }
        }

        .filing-group-item {
          margin-left: 0.5rem;
        }
      }

      .filing-group-options-select {
        grid-row-gap: 20px;
        display: grid;
        margin-bottom: 60px;

        .select-label-section {
          display: flex;
          align-items: baseline;
        }
      }
    }
  }

  .calcOptionsTabFields {
    @extend .fields;

    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      'calcOptionsSelectGroup calcOptionsSelectGroup'
      'tax-type tax-type'
      'common-calc-preferences common-calc-preferences'
      'get-consolidate-proforma-values get-consolidate-proforma-values'
      'state-calc-preferences state-calc-preferences';
  }

  .calcOptionsSelectGroup {
    grid-area: calcOptionsSelectGroup;
  }

  .taxType {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    grid-area: tax-type;
  }

  .getConsolidateProformaValues {
    grid-area: get-consolidate-proforma-values;
  }

  .commonCalcPreferences {
    grid-area: common-calc-preferences;
  }

  .stateCalcPreferences {
    grid-area: state-calc-preferences;
  }

  .buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid variables.$color-light-grey;
    padding: 10px 20px;
    background-color: variables.$color-white;
  }

  .singleButton {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid variables.$color-light-grey;
    padding: 10px 20px;
  }

  .nonFilingDisplay {
    display: flex;
    font-size: 0.875rem;
  }

  .nonFilingTooltipIcon {
    margin-left: 0.25rem;
    color: variables.$color-orange-1;
  }

  .fillingInformation {
    border: 1px solid variables.$color-pwc-appkit-grey;
    border-radius: 4px;
    padding: 1rem;
    background-color: variables.$color-concrete;
  }

  .filingInfoHeader {
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .filingInfoMessage {
    margin-left: 1.5rem;
    font-size: 0.75rem;
  }

  .fillingInformationIcon {
    color: variables.$color-pwc-appkit-grey-darker;
    opacity: 0.7;
  }

  .headerLabelSection {
    height: 32px;
    margin-bottom: 10px;
  }

  .headerLabelText {
    font-size: 14px;
  }

  .checkedCountBadge {
    display: inline-block;
    height: 1.5rem;
    margin-left: 8px;
    border-radius: 100%;
    padding: 0 0.535rem;
    background-color: rgb(208, 74, 2);
    color: #fff;
    font-size: 0.85rem;
    font-weight: 600;
    line-height: 1.5rem;
    align-items: center;
  }

  .filingGroupBadgeGroupContainer {
    margin-bottom: 10px;

    :global {
      .a-form-error {
        margin-top: 0;
      }
    }
  }

  .filingGroupBadgeGroupHeader {
    font-size: 12px;
  }

  .filingGroupBadgeWrapper {
    padding: 5px 0;

    :global {
      .spinner-container {
        margin-left: 8px;
      }

      .a-badge {
        color: #fff;
      }

      .a-badge-primary {
        background-color: #000;
      }

      .a-badge-secondary {
        background-color: #f00;
      }
    }
  }

  .filingGroupBadgeButtonWrapper {
    margin-left: 10px;
    background-color: transparent;

    &:first-child {
      margin-left: 0;
    }

    i {
      margin-right: 4px;
      margin-left: 8px;
      font-size: 10px;
    }
  }

  .editOptionTitle {
    margin-bottom: 0.625rem;
  }

  .editOptionField {
    margin-bottom: 0.625rem;
  }

  .editOptionContainer {
    display: flex;
    flex-direction: column;
  }

  .calcPreferenceBanner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
