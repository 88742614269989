.collapseIcon {
  position: absolute;
  top: 255px;
  left: 365px;
  cursor: pointer;

  .expandSvg {
    display: none;
  }

  .collapseSvg {
    display: block;
  }

  &.showExpandSvg {
    .expandSvg {
      display: block;
    }

    .collapseSvg {
      display: none;
    }
  }
}
