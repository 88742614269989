@use '../../style/variables';

.redTextWrapper {
  color: variables.$color-red;
}

.dataGridContainer {
  padding: 0 20px;

  :global {
    .ag-theme-bootstrap {
      .ag-row {
        .ag-cell-value {
          &.editable-dropdown-cell {
            padding-right: 10px;

            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 3px;
              bottom: 0;
              width: 6px;
              height: 6px;
              margin: auto 0;
              border: 1px solid;
              border-top: none;
              border-left: none;
              transform: rotate(45deg);
            }
          }
        }
      }
    }

    .ag-grid-custom {
      .ag-theme-bootstrap:not(.ag-popup) {
        height: calc(100% - 30px);
        margin-top: -5px;
      }
    }
  }
}

.datagridHeader {
  display: flex;
  position: absolute;
  color: #2d2d2d;
  font-size: 16px;
  font-weight: 500;
  z-index: 10000;

  a {
    margin-left: 10px;
  }

}
