@use '../../../style/_variables.scss';

.validationStatus {
  display: inline;
  margin: 0.5rem;
  padding: 5px;
  color: white;
  font-weight: bold;
}

.validationPassed {
  background-color: variables.$success-status-color;
}

.validationFailed {
  background-color: variables.$error-status-color;
}

.validationWarning {
  background-color: variables.$warning-status-color;
}
