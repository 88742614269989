@use '../../../style/_variables.scss';

.popupWrapper {
  position: fixed;
  min-width: 8rem;
  border: 1px solid variables.$color-opacity-black;
  box-shadow: variables.$box-shadow-menu;
  padding: 10px;
  background-color: variables.$color-white;
  font-size: 0.875rem;
  line-height: 1rem;
  z-index: 100001;
  overflow-y: auto;

  li {
    z-index: 100002;

    :first-child {
      display: flex;
      padding: 8px 5px;
    }

    .link {
      color: variables.$color-orange-1;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .itemDisabled {
    pointer-events: none;
  }

  .loadingFieldDrillInfoWrapper {
    display: flex;
    margin-top: 5px;
    align-items: center;
    padding: 5px;

    span {
      margin-right: 5px;
    }
  }

  .loadingFieldDrillInfoLoading {
    display: flex;
  }

  hr {
    margin: 0;
  }

  li:not(.itemDisabled):hover {
    background-color: variables.$color-ghost;
    cursor: pointer;
  }

  .popupInfo {
    display: flex;
    align-items: baseline;
    pointer-events: none;
  }

  .popupInfoHeader {
    font-size: 1rem;
  }

  .popupInfoSource {
    @extend .popupInfoHeader;

    font-weight: bold;
  }
}
