.accountsContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1;
}

.accountsHeader {
  flex-wrap: wrap;
}
