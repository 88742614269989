.editWatermarkForm {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.editWatermarkFormFields {
  display: flex;
  flex-direction: column;
}

.previewButton {
  align-self: center;
  margin: 10px 0 0;
}
