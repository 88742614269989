.editInfoAccountForm {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.inputsSection {
  flex-grow: 1;
}

.invalidGlobalIndicatorWarning {
  margin-bottom: 0.5rem;
}
