.infoAccountsContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1;
}

.infoAccountsHeader {
  justify-content: flex-end;
}

.gridContainer {
  display: flex;
  height: 100%;
  flex-direction: column;

  :global {
    .ag-theme-bootstrap {
      .ag-menu-separator-cell::after {
        display: block;
        border-top: solid 1px;
        border-top-color: #babfc7;
      }
    }
  }
}
