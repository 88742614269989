@use '../../style/variables';

.overridableFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  pointer-events: none;
  overflow: hidden;

  :global {
    a {
      pointer-events: auto;
    }
  }
}

.formPage {
  background-color: unset !important;
}

.watermarkDefaults {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: pre;
  align-items: center;
  justify-content: center;
}

.watermarkOutlined {
  -webkit-text-stroke: 3px variables.$watermark-text-stroke-color;
  /* stylelint-disable-next-line property-no-unknown */
  text-stroke: 3px variables.$watermark-text-stroke-color;
}

.suppressTooltip {
  z-index: 10000000;
  pointer-events: all;
}
