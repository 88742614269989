@use '../../../../style/variables';

$default-field-indent: 20px;
$checkbox-text-indent: 47px;
$columns-template-default: 10rem 1fr;
$columns-template-reverse: 3fr 1fr;
$full-width-columns: '1/3';
$min-panel-width: 345px;
$control-font-size: 0.75rem;
$control-input-height: 1.5rem;
$select-arrow-top: 0.25rem;
$data-type-text-width: 150px;

.formWrapper {
  display: flex;
  flex-direction: column;
  min-width: $min-panel-width + 30px;
  height: 100%;
  padding-top: 1em;
  overflow: hidden;
}

.panel {
  padding: 0 1em;
}

.header {
  grid-column: #{$full-width-columns};
  margin-bottom: 0;
}

.subHeader {
  grid-column: #{$full-width-columns};
  margin: 10px 0 0 10px;
  font-size: $control-font-size;
  font-weight: 500;
}

.scrollingSection {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.sectionDisabled {
  :global {
    h6,
    p {
      color: variables.$disabled-color;
    }
  }
}

.formColumn {
  display: grid;
  grid-template-columns: $columns-template-default;
  min-width: $min-panel-width;
  align-items: flex-start;
}

.formColumnReverse {
  @extend .formColumn;

  grid-template-columns: $columns-template-reverse;
}

.numberField {
  :global {
    .a-input-text {
      padding: 0;
      text-align: right;
    }
  }
}

.gridField {
  grid-column: #{$full-width-columns};
  display: grid;
  grid-template-columns: inherit;
  align-items: center;
  margin: 2px 0;

  :global {
    .a-form-error,
    .invalid-feedback {
      grid-column: 2;
    }

    .Select-value-label,
    .a-input-text {
      height: $control-input-height;
      font-size: $control-font-size;
      line-height: $control-input-height;

      &:disabled {
        color: variables.$color-silver-chalice;
      }
    }

    .sdk-custom-select-wrapper {
      .sdk-custom-select {
        .Select-control {
          height: $control-input-height;

          .Select-multi-value-wrapper {
            .Select-placeholder,
            .Select-input,
            .Select-value {
              height: $control-input-height;
              line-height: $control-input-height;

              input {
                padding: 0;
              }
            }
          }
        }
      }

      .sdk-custom-select-appkit-icon {
        top: $select-arrow-top;
      }

      .Select-menu-outer {
        top: 30px;
      }
    }

    .a-form-label {
      grid-column: 1;
      margin: 0 0 0 $default-field-indent;
    }
  }
}

.gridCheckbox {
  grid-column: #{$full-width-columns};
  margin: 3px $default-field-indent;

  :global {
    .a-checkbox-text {
      font-size: $control-font-size;
    }
  }
}

.fieldMappingFooter {
  grid-column: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dataTypeAndAttribute {
    font-size: $control-font-size;

    .dataType {
      display: inline-block;
      width: $data-type-text-width;
    }

    .aliasName {
      display: inline-block;
    }
  }
}

.helpLink {
  font-size: $control-font-size;
  font-weight: 400;
}

.wrappingOptions {
  :global {
    .a-form-label {
      margin-left: $checkbox-text-indent;
    }
  }
}

.fieldDisabled {
  :global {
    .a-form-label {
      color: variables.$disabled-color;
    }
  }
}

.fieldMappingHeader {
  @extend .header;

  display: flex;
  justify-content: space-between;
}

.alignStart {
  align-items: flex-start;
}

.formGuidFooter {
  display: flex;
  padding: 0.3em 0;
  font-size: 0.625rem;
}
