@use '../style/_variables.scss';

.selectCol {
  max-width: 240px;
}

.selectGrid {
  margin-top: 16px;
}

.buttonControls {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  button {
    margin-left: 8px;
  }
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.location-lock-icon {
  margin-right: 10px;
}

.buttonMessage {
  display: block;
  margin-bottom: 0;
  margin-left: 8px;
  color: variables.$color-grey-inactive;
  font-size: 0.75rem;
  line-height: 1.1875rem;
  white-space: nowrap;
}
