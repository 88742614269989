@use "sass:color";
@use "../../../style/variables";

.draggable-lists {
  .table-header {
    > div {
      border-bottom: 1px solid black;
      padding: 10px;
      font-weight: bold;
      text-align: center;
    }
  }

  .table-footer {
    > div {
      text-align: center;
    }
  }

  .droppable-zone {
    padding: 10px;

    &:first-child {
      border-right: 1px solid black;
    }

    &.is-dragging-over {
      background: color.adjust(variables.$color-orange-1, $lightness: 20%);
    }
  }

  .list-item {
    user-select: none;
    margin-bottom: 8px;
    border: 2px solid variables.$color-orange-1;
    border-radius: 3px;
    padding: 6px 10px;
    background: variables.$color-white;

    &.is-dragging {
      background: variables.$color-orange-1;
      color: variables.$color-white;
    }
  }
}
