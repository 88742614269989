.formWrapper {
  width: 100%;
}

.formView {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.pdfFormContent {
  :global {
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      margin-bottom: 0;
    }
  }
}

.dataInfo {
  :global {
    .data-info-paragraph {
      max-width: 40vw;
      word-wrap: break-word;
    }
  }
}
