@use '../../../style/variables.scss';

:global {
  .ag-title {
    color: variables.$color-mine-shaft;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: bold;
  }

  .ag-theme-bootstrap {
    .ag-header {
      background-color: variables.$color-light-grey;
    }

    .ag-header-cell-label {
      color: variables.$color-mine-shaft;
      font-family: 'Helvetica Neue', sans-serif;
      font-size: 12px;
      font-weight: bold;
      overflow: hidden !important;
    }
  }

  .ag-header-viewport {
    background-color: variables.$color-light-grey;
  }
}

.headerBorders {
  :global {
    .ag-header-cell:not(.no-border)::before {
      content: '';
      position: absolute;
      left: 0;
      height: 80%;
      border-left: solid thin variables.$color-ag-header-group-cell-with-group;
    }
  }
}
