@use '../style/variables';

.userMenuPopoverPanelWrapper {
  position: absolute;
  top: 3rem;

  .userMenuPopoverPanel {
    width: 18.75rem;
    padding: 0.625rem;

    .userMenuPopoverBody {
      border-bottom: 1px solid variables.$color-alto;
      padding: 1rem 0;
    }

    .logoutButton {
      display: flex;
      justify-content: center;
      padding-top: 0.625rem;
    }
  }
}
