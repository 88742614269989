.invalidIconEmptyCell {
  position: absolute;
  right: 0;
  color: red;
}

.invalidIcon {
  @extend .invalidIconEmptyCell;

  top: -1.33em;
}
