.paramsContainer {
  display: flex;

  & > div {
    flex: 1;
    margin: 0 0.5em;
  }
}

.reportParam {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;

  & > div {
    flex: 1;
  }

  .reportParamDropdown {
    width: 200px;
    margin-right: 10px;
  }

  .runReportBtn {
    margin: 0 auto 5px auto;
  }

  .noBottomMargin {
    margin-bottom: 0;
  }
}

.extendedWidthParamDropdown {
  width: 37em;
  margin-right: 10px;
}
