@use '../../style/_variables.scss';

.filingDecisionsForm {
  margin-bottom: 15px;
}

.filingDecisionsColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 200px;
  min-width: 200px;
  margin: 0 16px;

  :global {
    .spinner-container--small {
      position: relative;
      height: 30px;
      text-align: left;
    }

    .a-input-text:disabled {
      background-color: variables.$color-white;
    }
  }
}

.filingDecisionsField {
  margin-top: 20px;
}

.filingDecisionsCheckbox {
  margin-top: 10px;
}

.filingDecisionsFormTitle {
  width: 300px;
  margin-bottom: 0;
  padding-top: 16px;
  font-size: 18px;
  font-weight: 500;
}

.filingAttributes {
  width: 200px;
  margin-bottom: 10px;
}

.filingDecisionsButtons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 !important;
  padding-right: 40px;
}

.filingDecisionsFormHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.stickyHeader {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: variables.$color-white;
  z-index: 1;
}

.calcPreferences {
  display: flex;
  height: 100%;
}

.dynamicFormWithoutExcess {
  flex: 1;
  flex-wrap: nowrap;
  margin-top: -28px;
  overflow: auto;
}

.consolDynamicFormWithoutExcess {
  @extend .dynamicFormWithoutExcess;

  margin-top: 0;
}
