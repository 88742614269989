@use '../style/variables';

:global {
  .subcategory-header {
    font-weight: 700;
  }

  .generic-category-grid .ag-row.subcategory-header-hidden {
    border-width: 0;
  }
}

.gridOptions {
  display: flex;
}

.showCategorySubtotalsCheckbox {
  width: 12rem;
  padding: 0.5em 0.1em 0.5em 0.5em;

  :global {
    .a-checkbox-text {
      color: variables.$color-black;
      font-size: variables.$regular-font-size;
    }
  }
}
