.checkboxesGroupWithInlineLabel {
  display: grid;
  grid-template-columns: 115px 1fr;
  align-items: center;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  .checkboxesGroup {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    align-items: center;

    & > div {
      margin: 7px 0;
    }

    :global {
      .a-form-label {
        margin: 7px 0;
      }
    }

    .formText {
      padding: 0 20px 0 0;
    }
  }

  .checkboxOutsideGridLayout {
    grid-column-start: 2;
    margin: 7px 0;
  }
}
