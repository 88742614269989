@use '../../style/_variables.scss';

.addButton {
  display: flex;
  flex: nowrap;
}

.taxRatesAndConstantsHeaderContainer {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: variables.$color-white;
  z-index: 1;
}

.taxRatesAndConstantsHeaderForm {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.taxRatesAndConstantsTitle {
  width: 300px;
  margin-bottom: 0;
  padding-top: 16px;
  font-size: 18px;
  font-weight: 500;
}

.taxRatesAndConstantsButtons {
  display: flex;
  align-items: flex-end;
  margin: 0 !important;
  padding-right: 40px;
}

.taxRatesAndConstantsButtonsGroup {
  margin: 0 !important;
}

.taxRatesAndConstantsErrorMessage {
  margin: 0;
}
