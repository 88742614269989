.search-bar {
  position: relative;
  height: 24px;
  line-height: 24px;
  z-index: 1000;
}
.search-bar .search-icon {
  float: right;
}
.search-bar .search-input-wrapper {
  position: relative;
  width: 100%;
  border: 1px solid #ddd;
  padding: 0 5px;
  float: right;
  box-sizing: border-box;
  animation-duration: 0.3s;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.search-bar .search-input-wrapper .input-wrapper {
  width: 100%;
  padding-right: 23px;
}
.search-bar .search-input-wrapper .search-icon-wrapper {
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
}
.search-bar svg {
  cursor: pointer;
}
.search-bar svg.search-icon {
  height: 24px;
  margin: 3px 6px;
}
.search-bar svg.close-icon {
  margin-top: -1px;
}
.search-bar input {
  width: 100%;
  border: 0;
  background-color: #fff;
  font-size: 1rem;
}
.search-bar input:focus {
  outline: none;
}

.search-bar-absolute {
  position: absolute;
  right: 20px;
  transform: translateY(-53px);
  z-index: 1000;
  width: 40%;
}

.icons-container {
  display: flex;
  justify-content: end;
}
.icons-container .a-tooltip-ref {
  cursor: pointer;
}
.icons-container div:nth-child(2) {
  margin-left: 6px;
}