@use '../../style/variables';

.headerButtons {
  display: flex;
  justify-content: flex-end;
}

.background {
  width: 100%;
  height: calc(100% - 3rem);
  padding: 10px;
}

.calculationGridContainer {
  display: flex;
  height: 100%;
  flex-direction: column;

  :global {
    .ag-theme-bootstrap {
      .ag-menu-separator-cell::after {
        display: block;
        border-top: solid 1px;
        border-top-color: #babfc7;
      }
    }
  }
}

.refreshButton {
  margin-bottom: 8px;
  margin-left: 15px;

  &:hover {
    color: variables.$color-silver-chalice;
  }
}

.infoMessage {
  margin: 6px 0 0 0;
  color: variables.$color-dark-3;
  font-size: 10px;
}

.compileReportButton {
  margin-right: 180px;
}

.contextAndLatestCalcCompileInfo {
  margin: 10px 0;
}

.latestCalcCompileInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.processingInformation {
  align-self: flex-end;
  margin: 0.3em 0 0.7em;
  font-size: 12px;
}

.validationInfoStatus {
  margin: 0;
  font-size: variables.$regular-font-size;
}
