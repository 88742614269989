@use "../../style/variables";
@use "../../style/z-indexes";

.left-menu {
  position: fixed;
  top: 50px;
  width: variables.$left-menu-width;
  height: 100vh;
  background-color: #fff;
  z-index: z-indexes.$left-menu-z-index;

  &.with-left-slide-in {
    box-shadow: variables.$box-shadow-slide-in;
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &:hover {
      text-decoration: none;
    }

    &--active {
      background-color: variables.$color-orange-1;
    }

    &--active .a-nav-item-text,
    &--active .appkiticon {
      color: #fff;
    }
  }

  .a-nav-item-text {
    font-size: 0.7rem;
  }
}
