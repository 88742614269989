@use '../../style/variables';

.gridOptions {
  display: flex;
  height: 34px;
  margin-bottom: 10px;

  .suppressZerosCheckbox {
    display: flex;
    align-items: center;
    padding-right: 10px;
    padding-left: 5px;

    span:first-child {
      align-self: normal;
    }

    span:nth-child(2) {
      color: variables.$color-black;
      font-size: 12px;
    }
  }

  .entityDisplay {
    display: flex;
    height: 34px;
    margin-left: auto;

    .entityDisplayLabel {
      width: 100px;
      margin-top: 8px;
      color: variables.$color-black;
      font-size: 12px;
      text-align: center;
    }

    .entityDisplaySelect {
      width: 150px;
      font-size: 12px;
    }
  }
}
