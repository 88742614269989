@use '../style/variables' as *;

$dashboard-bottom-cards-height: 1px;
$dashboard-entities-table-breakpoint: 1300px;
$dashboard-top-cards-height: calc(
  100vh - #{$main-menu-height} - #{$dashboard-bottom-cards-height} - #{$footer-total-height}
);

.dashboard {
  overflow-y: auto;

  .top-cards {
    min-height: $dashboard-top-cards-height;
  }

  .context-card {
    display: flex;
    min-height: 50px;
    flex-wrap: wrap;
    justify-content: center;

    .global-context {
      margin: 0 10px;

      .global-context-values {
        flex-basis: 60%;
        flex-wrap: wrap;
      }

      .global-context-value-wrapper {
        padding: 2px 0;

        .global-context-value {
          white-space: nowrap;
        }
      }

      @media (min-width: $bootstrap-xl) {
        .global-context-values {
          flex-basis: 70%;
        }
      }
    }
  }

  .entities-table {
    height: calc(#{$dashboard-top-cards-height} - 65px);

    .home-card-placeholder {
      margin-top: 20px;
      text-align: center;
    }
  }

  .entities-table-header {
    display: flex;
    flex-wrap: wrap;
  }

  .entities-table-data {
    padding-top: 5px;
    padding-bottom: 5px;
    column-gap: 40px;
    column-count: 2;

    @media (max-width: $dashboard-entities-table-breakpoint) {
      column-count: 1;
    }

    .entity-by-country {
      cursor: pointer;

      &:hover {
        background-color: $color-barley-white;
      }
    }
  }

  .home-card {
    height: $dashboard-bottom-cards-height;

    .home-card-element {
      margin-top: 8px;
    }

    .key-row {
      word-wrap: normal;
    }

    .home-card-placeholder {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .heatmap {
    position: relative;
    height: 100%;
    padding-bottom: 0% !important;

    .zoom-header {
      position: absolute;
      z-index: 1;
    }

    .zoom-icon {
      margin: 5px;
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }
    }

    .heatmap-range {
      $gray-color: #e0e0e0;
      $lighter-color: #b3dcf9;
      $light-color: #4dacf1;
      $blue-color: #0089eb;
      $dark-color: #0060d7;
      $darker-color: #003dab;
      $number-of-segments: 3;

      position: absolute;
      bottom: 40px;
      left: 40px;
      height: calc(100% - 80px);
      max-height: 270px;

      .heatmap-range-bar {
        @function get-range-gradient($segment-number) {
          @if $segment-number == 1 {
            @return linear-gradient($gray-color, $lighter-color);
          }

          @if $segment-number == 2 {
            @return linear-gradient($lighter-color, $light-color);
          }

          @if $segment-number == 3 {
            @return linear-gradient($light-color, $dark-color);
          }

          @if $segment-number == 4 {
            @return linear-gradient($darker-color);
          }
        }

        height: 100%;

        .bar-segment {
          width: 20px;
          height: calc(100% / #{$number-of-segments} - 1px);
          margin-bottom: 1px;
          background: $color-white;
        }

        @for $i from 1 through $number-of-segments {
          .bar-segment:nth-child(#{$i}) {
            background-image: get-range-gradient($i);
          }
        }
      }

      .heatmap-range-labels-wrapper {
        display: flex;
        position: absolute;
        bottom: calc(-100% / 2 / #{$number-of-segments});
        left: 30px;
        height: calc(100% + 100% / #{$number-of-segments});
        font-size: 14px;
        font-weight: bold;
        flex-direction: column;
        justify-content: space-around;
      }
    }
  }

  .icon-link {
    margin-left: 10px;
  }
}

.account-expire-message {
  display: flex;
  position: relative;
  align-items: end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: large;
  overflow: hidden;
  z-index: 1000;
}

.account-expire-message h4 {
  margin-right: 0;
  margin-left: 0;
  color: #d04a02;
  font-size: 2rem;
  text-align: center;
}

.account-expire-message p {
  margin: 4%;
  padding: 0;
  text-align: center;
}

.inline-line {
  margin: 5%;
  border-top: 2px solid #d04a02;
}
