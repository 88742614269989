@use '../../style/variables';

.formListDropdown {
  width: 35%;
  min-width: 150px;
  max-width: 300px;
  margin-right: 20px;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tableHeaderText {
  width: 850px;
  margin: 0 auto;
  font-size: 0.9rem;
  text-align: center;
}

.disableLink {
  pointer-events: none;
  color: variables.$text-color;
}
