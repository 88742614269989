@use '../../../style/variables';

.switchContainer {
  display: flex;
  flex-direction: row;
}

.switchTooltipIcon {
  margin-left: 0.25rem;
  color: variables.$color-orange-1;
}

.switchTooltip {
  max-width: 500px;
  z-index: 10;
}
