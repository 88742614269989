@use "../../../style/variables";
@use "../../../style/z-indexes";

.slide-in-overlay {
  z-index: z-indexes.$slide-in-z-index;

  .slide-pane__header {
    height: 50px;
    flex: 0 0 50px;
  }

  .slide-pane__close {
    margin-left: 0;
    padding: 0 32px 0 12px;
    transform: rotate(180deg);
  }

  .slide-pane__title-wrapper {
    height: 24px;
    margin-left: 0;
    text-align: center;

    .slide-pane__title {
      max-width: 90%;
      line-height: 24px;
    }
  }

  .slide-in-field {
    margin-bottom: 1rem;
  }

  .slide-in-description-field textarea {
    max-height: 280px;
  }

  .slide-in-footer {
    right: 0;
    bottom: 0;
    left: 0;
    padding: 24px 0;
  }

  .buttons-group {
    right: 0;
    bottom: 0;
    left: 0;
    padding: 24px 0;
  }
}

.slide-in-footer.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 24px 32px;
}

.left-title-panel {
  .slide-pane__header {
    flex-direction: row-reverse; // reversing to switch places of the title and icon
    border-bottom: 1px solid variables.$color-light-grey;
    padding: 0 20px;
    background: variables.$color-white;
  }

  .slide-pane__close {
    padding-left: 0;
  }

  .slide-pane__title-wrapper {
    text-align: left;
  }
}
