@use '../../../style/_variables.scss';

$data-type-text-width: 150px;

.paramsRow {
  display: inline-flex;
  margin: 15px 0;
  flex-shrink: 0;
}

.formButtons {
  display: flex;
  justify-content: flex-end;
}

.paramsContainer {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  row-gap: 15px;

  .paramsLastRow {
    grid-column-start: 2;
    display: flex;
    margin-top: -5px;
    align-items: flex-start;
    justify-content: space-between;

    .paramsLastRowText {
      display: inline-block;
      width: 100%;
      justify-content: start;
    }
  }

  :global {
    .col {
      padding-left: 0;
    }

    .a-form-label {
      display: flex;
      align-items: center;
      min-width: 70px;
      margin-right: 12px;
      margin-bottom: 0;
      white-space: nowrap;
    }

    .spinner-container {
      height: auto;
      text-align: left;
    }

    .sdk-custom-select-wrapper {
      width: 100%;
      margin-right: 5px;
    }

    .Select--single > .Select-control .Select-value {
      padding-left: 0;
    }
  }
}

.diagnosticGrid {
  margin-bottom: 10px;

  :global {
    .ag-header-cell-label {
      justify-content: flex-start !important;
    }
  }
}

.expressionWrapper {
  width: 100%;
  height: 100%;
}

.expressionView {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.expressionRows {
  flex: 1;
  overflow-y: auto;
  display: flex;
  padding: 15px;
}

.expressions {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.expressionValidation {
  display: flex;
  flex: 1;
  flex-direction: column;

  .expressionLabels {
    display: flex;
    justify-content: space-between;
  }

  .checkboxGroupWithErrors {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .checkboxGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  :global {
    .form-text {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-bottom: 10px;

      .a-textarea-compound {
        flex: 1;

        .a-textarea {
          height: 9.5em;
          background-color: variables.$color-white;

          &:disabled {
            background-color: variables.$color-wild-sand;
          }
        }
      }

      /* workaround for current TextArea not supporting multiple labels */
      .a-form-label {
        display: none;
      }
    }
  }

  &.notes {
    :global {
      .form-text {
        .a-textarea-compound {
          .a-textarea {
            height: 4em;
          }
        }
      }
    }
  }
}

.inlineText {
  display: inline-block;
  font-size: 12px;
  white-space: nowrap;
}

.dataType {
  @extend .inlineText;

  width: $data-type-text-width;
}
