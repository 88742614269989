.a-modal .modal-dialog .modal-title {
  font-size: 1.125rem;
  font-weight: 500;
  color: #2D2D2D;
  line-height: 1.875rem;
}
.a-modal .modal-dialog.baseline-modal-showcase .modal-body {
  padding: 20px;
}
.a-modal .modal-dialog .modal-footer .help-text {
  font-size: 0.75rem;
  color: #2D2D2D;
}