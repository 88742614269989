@use '../style/variables';

.notificationPanelWrapper {
  position: absolute;
  top: 50px;
  right: 105px;

  .notificationPanel {
    width: 410px;

    :global {
      .a-panel-header {
        padding-right: 10px;
      }
    }

    .notificationDismiss {
      display: flex;
      justify-content: flex-end;
      padding: 10px 0;
      color: variables.$color-orange-1;
      cursor: pointer;
    }

    .notificationList {
      /*
        156px = 50px from the top, 50px panel header, 15px margin-top, 41px .notificationDismiss (with padding)
        This calc covers a situation, where screen height is lower than 480px
      */
      height: calc(100vh - 156px);
      max-height: 430px;
      margin-top: 15px;
      overflow: auto;
    }

    .notificationEmptyPage {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 32px;
      height: 100%;
      color: variables.$color-dark-3;

      .emptyPageIcon {
        color: variables.$color-pwc-appkit-grey;
        font-size: 72px;
        pointer-events: none;
      }

      .emptyPageText {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
}
