@use '../../style/variables';

.returnContext {
  display: flex;

  :global {
    .sdk-custom-select-global-context-dropdown {
      width: 250px;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
}

.returnBinderDropdown {
  :global {
    .sdk-custom-select-global-context-dropdown {
      width: 500px;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
}

.whiteBox {
  border-radius: 3px;
  box-shadow: variables.$box-shadow-card;
  padding: 16px;
  background: variables.$color-white;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.subStepBox {
  margin: 40px 0;
}

.actionBox {
  margin-bottom: 10px;
}

.stepStatus {
  display: flex;

  .capitalizeStatus {
    margin-left: 4px;
  }

  .capitalizeStatus::first-letter {
    text-transform: capitalize;
  }
}

.actionTitle {
  margin-bottom: 0;
}

.align {
  align-items: center;
}

.inline {
  display: flex;

  .lastUpdateDate {
    margin-left: 20px;
  }
}

.debugInfoIcon {
  margin-left: 5px;
}
