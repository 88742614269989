@use '../../../style/variables';

.contentWrapper {
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.actionsWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  > div {
    flex: 1;
  }

  > div:last-child {
    text-align: right;
  }
}

.suppressZerosCheckbox {
  padding: 0.5em 0.1em 0.5em 0.5em;

  span:first-child {
    align-self: normal;
  }

  span:nth-child(2) {
    color: variables.$color-black;
    font-size: 12px;
  }
}
