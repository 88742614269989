@use 'sass:color';
@use '../../../style/_variables.scss';

.global-context {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .global-context-values {
    display: flex;
    flex: 1;
    justify-content: center;
  }
}

.global-context-dropdown {
  margin-bottom: 5px;

  .spinner-container {
    display: flex;
    height: 27.5px;
  }
}

.global-context-dropdown,
.global-context-value-wrapper {
  .a-form-label {
    margin: 3px 5px 2px 0;
    white-space: nowrap;
  }

  .spinner {
    width: 20px;
    height: 20px;
    margin: 0;
  }

  .global-context-value {
    font-size: variables.$medium-font-size;
  }
}

.global-context-value-wrapper {
  display: flex;
  margin-left: 10px;
  align-items: center;

  .global-context-value {
    .spinner {
      position: static;
    }
  }
}

.static-labels {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .static-label {
    margin-left: 15px;
  }

  .key {
    margin-right: 5px;
  }

  .value {
    font-style: italic;
  }
}

.global-context.global-context-nav {
  justify-content: flex-end;
}

.global-context-dropdowns-wrapper {
  margin-bottom: 1rem;
}

.context-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.context-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.context-button-wrapper:hover {
  cursor: pointer;
}

.pin-icon-container {
  margin-left: 10px;

  .unpin-svg {
    display: none;
  }

  .pin-svg {
    display: block;
  }

  &.show-unpin-svg {
    .unpin-svg {
      display: block;
    }

    .pin-svg {
      display: none;
    }
  }
}

.calc-status-indicator {
  display: flex;
  background: none;

  &.has-link {
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &:not(.has-link) {
    cursor: inherit;
  }

  &.up-to-date {
    color: variables.$color-fern;

    &.has-link:hover {
      color: color.scale(variables.$color-fern, $lightness: 30%);
    }
  }

  &.dirty {
    color: variables.$color-red;

    &.has-link:hover {
      color: color.scale(variables.$color-red, $lightness: 30%);
    }
  }

  &.calculating {
    color: variables.$color-yellow;

    &.has-link:hover {
      color: color.scale(variables.$color-yellow, $lightness: 30%);
    }
  }
}
