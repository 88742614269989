@use "../../style/variables";

.helpIcon {
  position: relative;

  :global {
    .a-icon-container .appkiticon {
      font-size: 1.375rem;
    }

    /* Styles below are overrides for Pendo to make it consistent with the notification badge for SLT notifications */
    .pendo-resource-center-badge-notification-bubble {
      top: 0.125rem !important;
      left: 1rem !important;
      width: 1.125rem !important;
      height: 1.125rem !important;
      background-color: variables.$color-orange-1 !important;
      font-size: 0.5rem !important;
    }

    .pendo-notification-bubble-unread-count {
      position: relative !important;
      top: 0 !important;
      width: 1.125rem !important;
      font-size: 10px !important;
      font-weight: 700 !important;
      line-height: 1.125rem !important;
    }
  }
}
