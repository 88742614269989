@use '../../style/variables';

.refreshDataButton {
  margin-right: 10px;
}

.suppressZerosCheckbox {
  width: 8rem;
  padding: 0.5em 0.1em 0.5em 0.5em;

  :global {
    .a-checkbox-text {
      color: variables.$color-black;
      font-size: variables.$regular-font-size;
    }
  }
}
