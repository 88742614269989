$max-z-index: 100000;

/* SLIDE IN */
$slide-in-z-index: 19100; /* badge added by Pendo has z-index of 19000 */

$tooltip-z-index: 100001; /* badge added by Pendo has z-index of 19000 */

/* MENU */
$left-menu-z-index: $slide-in-z-index - 10;
$top-menu-z-index: $left-menu-z-index + 9;

/* MODALS */
$modal-z-index: 100000;
$appkit-select-options-in-modal-z-index: $modal-z-index + 10;
$side-panel-z-index: 99999;

/* ALERTS / NOTIFICATIONS */
$alert-z-index: 100000;

/* SPINNER */
$spinner-z-index: 30;
