@use "../../../style/variables";
@use "../../../style/z-indexes";

.sdk-custom-select-wrapper {
  position: relative;

  .sdk-custom-select-appkit-icon {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    color: variables.$color-silver-chalice;
    pointer-events: none;
  }

  .Select-value {
    display: inline-flex;
    border: 0;
    align-items: center;
    padding: 0 10px;
    color: #fff;
    white-space: nowrap;
    order: 1;

    .Select-value-label {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .Select-value-icon {
      border: 0;
      cursor: pointer;
      order: 2;

      &:hover {
        border: 0;
        background-color: inherit;
        color: inherit;
        cursor: pointer;
      }
    }
  }

  .sdk-custom-select {
    font-family: variables.$appkit-font-family;
    font-size: 14px;

    &.is-open .Select-value {
      display: none;
    }

    &:not(.sdk-custom-select--clearable) .Select-control .Select-value {
      padding-right: 28px;
    }

    .Select-arrow {
      visibility: hidden;
    }

    &:not(.sdk-custom-select--clearable) .Select-clear-zone {
      display: none;
    }

    .Select-clear-zone:hover .Select-clear {
      color: variables.$color-orange-1;
    }

    .Select-input {
      width: 0;
      margin-left: 10px;
      padding-right: 0;
      padding-left: 0;
    }

    &:not(.is-open) .Select-input {
      overflow: hidden;
    }

    .Select-control {
      height: 34px;
      border: none;
      border-radius: 0;
      background-color: variables.$color-concrete;
      cursor: pointer;
    }

    &.is-open,
    &.is-focused {
      .Select-control {
        border: none;
        border-radius: 0.125rem;
        box-shadow: variables.$box-shadow-appkit-field;
        cursor: pointer;
      }
    }

    &.is-disabled {
      .Select-control {
        cursor: initial;
      }
    }

    .Select-menu-outer {
      position: absolute;
      top: 46px;
      border: 1px solid variables.$color-silver !important;
      overflow: hidden;
      z-index: 1001;
      cursor: pointer;

      .VirtualizedSelectOption {
        line-height: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &.VirtualizedSelectOptionWithDesc {
          line-height: unset;
        }
      }

      .VirtualizedSelectOption:hover:not(.VirtualizedSelectSelectedOption),
      .VirtualizedSelectFocusedOption {
        background-color: #eee;
      }

      .VirtualizedSelectSelectedOption {
        background-color: variables.$option-selected-background;
        font-weight: 500;
      }
    }
  }

  .sdk-custom-select.is-open {
    .Select-control {
      background-color: variables.$color-white;
    }

    & + .sdk-custom-select-appkit-icon {
      transform: rotateX(180deg) translateY(1px);
    }
  }
}

body > div > .a-tooltip {
  z-index: z-indexes.$tooltip-z-index !important;
}

/* hack for testing issue described in SLT-10104
 the issue seems to be related to a bug in the appkit tooltip
 it can display after being disabled when the select option changes and then loses focus */
body > div > .a-tooltip:not([x-placement]) {
  display: none;
}

.a-select-list {
  div.list-item-wrapper {
    li.a-list-item {
      height: auto;

      .select-option-label {
        font-size: 14px;
      }

      .a-select-comment {
        font-size: 8px;
      }
    }
  }
}
