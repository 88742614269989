@use "../../../style/variables";

.action-menu {
  position: relative;
  margin-left: 10px;
  z-index: 2;
  cursor: pointer;
}

.action-menu-active {
  position: absolute;
  top: 30px;
  right: 0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2), -2px 2px 2px rgba(0, 0, 0, 0.2);
  background: #fff;
}

a.action-menu-item-link:hover {
  color: inherit;
  text-decoration: none;
}

.action-menu-item {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-size: 12px;
  white-space: nowrap;

  &:hover {
    background-color: variables.$color-grey-info;
  }

  &:disabled {
    opacity: 0.6;
  }
}
