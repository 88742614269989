.data-info {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;

  .data-info-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .data-info-paragraph {
    margin: 0;
    padding: 0;
    font-size: 24px;
  }

  .info-circle-icon {
    margin-right: 10px;
  }

  .info-spinner {
    margin: 7px 10px 0 0;

    .spinner-container--small .spinner {
      width: 20px;
      height: 20px;
      margin: 0 10px;
    }
  }
}
