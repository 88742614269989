.editTaxFormFieldForm {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.inputsSection {
  flex-grow: 1;
}

.checkboxesGroup {
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;

  & > div {
    width: 50%;
  }
}

.checkboxesGroupLabel {
  margin-top: 1rem;
}
