.location-lock-icon {
  margin-right: 10px;
}

.button-container {
  display: inline-flex;
  justify-content: flex-end;
  width: 160px;
  flex-shrink: 0;
}

.edit-mode-button {
  width: 45%;
}
