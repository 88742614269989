.overridableIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  font-size: 12px;
  z-index: 1;
  pointer-events: none;

  &::before {
    pointer-events: none;
  }
}
