.input {
  width: 100%;
  border: none !important;
  padding: 5px;
}

.clearIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}

.clearIcon:hover {
  cursor: pointer;
}
