@use '../../../../style/variables';

.agGridContainer {
  margin-bottom: 1rem;
  border: 1px solid variables.$color-pwc-appkit-grey;
}

.agGridHeader {
  margin-top: 1rem;
}
