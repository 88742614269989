@use '../../../../../style/variables';

.warningsAndErrorsContainer {
  display: flex;
  align-items: center;
  width: 70%;
  border: solid 1px variables.$color-pwc-appkit-yellow;
  border-radius: variables.$border-radius-size-select;
  padding: 10px;
  background-color: variables.$color-grey-yellow;
  justify-content: space-between;

  .warningsAndErrorsIconAndTextContent {
    display: flex;
    align-items: center;

    .alertIcon {
      padding: 10px 25px;
      color: variables.$color-pwc-appkit-yellow;
    }
  }

  :global {
    .a-checkbox-wrapper {
      padding-right: 20px;
    }
  }
}

.warningsErrorsSubtext {
  font-size: 0.8rem;
}
