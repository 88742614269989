@use '../../../../style/_variables.scss';

.overridableDatePicker {
  :global {
    .appkit-datepicker-custom-wrapper {
      line-height: inherit;
    }

    .calendar-wrapper {
      .react-calendar {
        top: 25px;
        min-width: 220px;
        transition: opacity 0.3s;
        opacity: 0;
      }
    }

    .calendar-icon {
      display: none;
    }

    .a-input-text {
      height: inherit;
      border: unset;
      padding-left: 15px;
      background-color: transparent;

      &:disabled {
        cursor: default;
      }

      &:focus {
        background-color: variables.$color-white;
      }
    }

    .invalid-feedback,
    .a-form-label {
      display: none;
    }
  }
}
