.uploadStatusContainer {
  display: flex;
  flex-direction: column;
  flex: 1;

  :global {
    .data-info {
      height: 100px;
    }
  }
}

.lastFileUploadInfo {
  font-weight: bold;
}
