@use './variables';
@use './z-indexes';

@mixin line-clamp($line-height, $number-of-lines: 1) {
  display: flex;
  display: -webkit-box;
  max-height: $number-of-lines * $line-height;
  line-height: $line-height;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: $number-of-lines;
  -webkit-box-orient: vertical;
}

@mixin slide-in-panel {
  .slide-pane__header {
    flex-direction: row-reverse; /* reversing to switch places of the title and icon */
    border-bottom: 1px solid variables.$color-light-grey;
    padding: 0 20px;
    background: variables.$color-white;
  }

  .slide-pane__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .slide-pane__close {
    padding-left: 0;
  }

  .slide-pane__title-wrapper {
    text-align: left;
  }

  .slide-pane__title {
    width: 85px;
    height: 24px;
    color: variables.$color-dark-3;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }
}
