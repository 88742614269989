.radioOptionWarpper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;
}

.modalContainer {
  min-width: none;
}
