@use "../../../../style/variables";

.subheader {
  display: flex;
  align-items: center;
  gap: variables.$efile-current-return-filing-sub-gap-value;
  color: variables.$color-dark-3;
  font-size: 12px;
}

.tableFormat {
  display: flex;
  height: 80%;
  margin: 1.875rem 1rem;
  margin-top: 0;
  flex-direction: column;
  border: 1px solid variables.$color-alto;
  border-radius: variables.$border-radius-size-select;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: variables.$color-white;
  justify-content: center;
  gap: variables.$efile-current-return-filing-sub-gap-value;
}

.informationPanel {
  margin: 1.875rem 1rem;
  border: 1px solid variables.$color-alto;
  border-radius: 3px;
  padding: 1.875rem 1.875rem 1.25rem;
  background-color: variables.$color-white;
  font-size: 0.875rem;
}

.informationPanelHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.informationLabel {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.informationField {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.backText {
  font-size: 14px;
}
