@use "../../../../style/variables";

.wizard {
  height: 84px;
  border-radius: variables.$border-radius-size-select;
  padding: 24px 50px;
  background-color: variables.$color-white;
  pointer-events: none;
}

.rightGroupedNavigationButtons {
  display: flex;
  gap: 16px;
}

.firstStepNextButton {
  justify-content: flex-end;
}

.lastStepRecheckStatusButton {
  justify-content: flex-end;
}
