@use "variables";

.ag-theme-bootstrap {
  input:not([type]),
  input[type='text'],
  input[type='number'],
  input[type='tel'],
  input[type='date'],
  input[type='datetime-local'],
  textarea {
    border: none;
  }

  .ag-popup-editor {
    background: white;
  }

  .ag-react-container {
    input:invalid {
      color: variables.$color-red;
    }

    .a-tooltip-ref-inner {
      outline: none;
    }

    .invalid-icon {
      position: relative;
      right: 15px;
      bottom: 24px;
      color: variables.$color-red;
    }
  }

  .ag-rich-select {
    & > .ag-rich-select-value {
      display: none;
    }

    .ag-rich-select-list {
      height: initial;
      max-height: 250px;
      overflow: auto;
    }

    .ag-virtual-list-container {
      padding-bottom: 10px;
      box-sizing: initial;
    }

    .ag-rich-select-row-selected {
      background: white;
    }

    .ag-rich-select-row {
      height: 30px;

      &:hover {
        background: variables.$color-orange-1;
        color: white;
      }
    }
  }

  .ag-input-wrapper input.ag-cell-edit-input {
    border-width: 0;
  }

  .ag-grid-select-editor-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    background: white;

    .spinner-container {
      height: 16px;
    }
  }

  .ag-grid-select-editor-wrapper {
    width: 170px;
    margin-top: -14px;
  }

  .ag-cell-value.editable-cell {
    border: 2px groove #ddd;
    padding: 3px 2px 4px 3px;
  }

  /* Custom override option for ag-grid checkbox controls to make grid selection checkbox match Appkit styling
     To use this, add 'custom-ag-grid-checkbox' to the cellClass on the column definition. */
  .custom-ag-grid-checkbox .ag-selection-checkbox .ag-checkbox-input-wrapper {
    display: block;
    position: relative;
    width: 1rem;
    height: 1rem;
    border: 0.0625rem solid variables.$color-silver-chalice;
    border-radius: 0.125rem;
    font-size: 0.625rem;

    .ag-checkbox-input {
      cursor: pointer;
    }

    &.ag-checked {
      border: none;
      background-color: variables.$color-orange-1;

      &::after {
        content: '\E826';
      }
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 2px;
      left: 1px;
      width: 1em;
      margin-right: 0.2em;
      margin-left: 0.2em;
      color: variables.$color-white;
      font-family: appkit-font, sans-serif;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-weight: 400;
      text-decoration: inherit;
      pointer-events: none;
    }
  }
}
