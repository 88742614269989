@use '../../../style/variables';

.container {
  display: grid;
  grid-template-columns: 1fr 8rem 1fr;
  grid-template-rows: 70px calc(100% - 70px);
  grid-template-areas:
    'data-model-dropdown . .'
    'available-datasets buttons assigned-datasets';
  gap: 1em;
  height: 100%;
  padding: 1em 2em 1em 1em;
}

.dataModelDropdown {
  grid-area: data-model-dropdown;
}

.availableDatasets {
  grid-area: available-datasets;
}

.assignedDatasets {
  grid-area: assigned-datasets;
}

.buttons {
  grid-area: buttons;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :global {
    button {
      margin-left: 0;
    }

    button:first-of-type {
      margin-bottom: 1em;
    }

    .a-btn {
      width: 100%;
    }
  }
}

.availableDatasets,
.assignedDatasets {
  position: relative;

  .topShadow {
    position: absolute;
    top: 40px;
    left: 1%;
    width: 98%;
    height: 1px;
    box-shadow: 0 5px 4px 1px variables.$color-white;
    z-index: 2;
  }

  ul {
    border: 3px solid variables.$color-mercury;
    box-shadow: 2px 3px 6px 2px variables.$default-background-color;
  }
}

.rightChevron {
  margin-left: 0.5em;
}

.leftChevron {
  margin-right: 0.5em;
}

.datasetsBox {
  margin: 1em 0;
  font-size: 0.75rem;
}

.customListHierarchy {
  height: 90%;
}
