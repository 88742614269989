.dropdown {
  display: flex;
  justify-content: center;
  width: 300px;
  margin-bottom: 5px;

  :global {
    .sdk-custom-select-global-context-dropdown {
      width: 250px;
    }
  }
}
