@use '../../../style/variables';

.headerSection {
  padding: 1.5rem 2rem;
  background-color: variables.$color-white;
  align-items: center;
}

.title {
  margin-bottom: 0;
  line-height: 2rem;
}

.description {
  margin-top: 0.625rem;
  font-size: 0.875rem;
}

.subHeader {
  margin-top: 1rem;
}

.preHeader {
  margin-bottom: 1rem;
}
