@use "../../../style/variables";

.datasetDefinitionsListWrapper {
  display: grid;
  /* 30px = margin of the header, 2rem = height of the header, 50px = margin of the footer */
  height: calc(100% - 30px - 2rem - 50px);
  grid-template-columns: auto 140px;
  grid-template-rows: 2rem 2fr 1fr;
  margin: 1.5rem 0 0;
  padding: 1.5rem 50px;
}

.datasetInstancesTitle {
  margin: 0;
}

.datasetInstancesGrid {
  grid-row: 3;
  grid-column: 1 / 3;
}
