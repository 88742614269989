@use '../../../style/_variables.scss';

.overriddenInput {
  :global {
    .a-input-text {
      color: variables.$color-red;
    }
  }
}

.fieldWrapper {
  pointer-events: all;

  :global {
    .a-input-text {
      height: inherit;
      padding: 0;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      text-align: inherit;
    }

    .a-input-compound {
      .a-input-text {
        padding: 0;
      }
    }
  }
}

.overridableWrapperEFileValidationError {
  :global {
    .a-input-compound {
      .a-input-text {
        padding-left: 15px;
      }
    }
  }
}

.overridableWrapper {
  &:hover {
    :global {
      .a-input-text {
        padding-left: 15px;
      }
    }
  }
}

.checkboxWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: variables.$color-white;
}

.overridenField {
  position: relative;
}

.overridenField::after {
  content: '';
  position: absolute;
  top: 0;
  right: -5px;
  border: 3px solid variables.$color-orange-1;
  border-radius: 2px;
  background-color: variables.$color-orange-1;
}

.eFileValidationBaseField {
  position: relative;
}
