@use '../../../../style/_variables.scss';

.overridableFormSelect {
  height: inherit;

  :global {
    .react-select__control {
      border-radius: 0.125rem;

      &.react-select__control--is-focused {
        border: none;
        box-shadow: variables.$box-shadow-appkit-field;
      }
    }

    .react-select__input-container {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    .react-select__dropdown-indicator {
      padding-top: 0;
      padding-bottom: 0;
    }

    .react-select__indicator-separator {
      margin-top: 3px;
      margin-bottom: 3px;
    }

    .react-select__value-container {
      padding-top: 0;
      padding-bottom: 0;

      &.react-select__value-container--has-value {
        .react-select__single-value {
          color: variables.$color-orange-1;
        }
      }
    }

    .react-select__menu {
      min-width: 200px;
      font-size: 14px;
    }

    .react-select__option {
      &.react-select__option--is-focused,
      &:active {
        background-color: variables.$color-concrete;
      }

      &.react-select__option--is-selected {
        background-color: variables.$option-selected-background;
        color: variables.$text-color;
        font-weight: 500;
      }
    }
  }

  &:hover {
    :global {
      .react-select__value-container {
        padding-left: 15px;
      }
    }
  }
}
