@use '../../../../style/variables';

.tab {
  display: flex;
  flex-direction: column;
  border: 1px solid variables.$color-alto;
  border-radius: variables.$border-radius-size-select;
  background-color: variables.$color-white;
}

.tabHeader {
  display: flex;
  width: 100%;
  flex-direction: row;
  border-bottom: 1px solid variables.$color-alto;
  padding: 24px 32px;
  justify-content: space-between;
  flex-wrap: wrap;

}

.headerBreakLine {
  margin: 0;
}

.headerIcon {
  margin-inline-end: 24px;
}

.headerTitles {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

.headerTitle {
  display: inline-block;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2.25rem;
}

.headerSubTitle {
  display: inline-block;
  font-size: 1rem;
  line-height: 2.25rem;
}

.tabContent {
  display: flex;
  flex-direction: column;
  padding: 24px 32px 32px;
  gap: variables.$efile-current-return-filing-sub-gap-value;
}
