@use '../../../style/variables';

.modal {
  :global {
    .modal-body {
      display: flex;
      flex-direction: column;
      max-height: 80vh;
      overflow: hidden;
    }

    .modal-footer {
      display: none;
    }
  }
}

.fieldsList > li {
  list-style-type: disc;
  margin-left: 30px;
}

.buttonsRow {
  margin-top: 16px;
  text-align: right;
}

.pageHeader {
  position: sticky;
  top: 0;
  padding: 15px 3px;
  background: variables.$color-white;
}

.fieldsInformation {
  margin: 10px 3px;
}

.validationDifferences {
  overflow-x: auto;
  margin-bottom: 15px;
}
