.spinner-container {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.spinner-container.global {
  width: 100%;
  max-width: 100vw;
  height: 100vh;
}

.spinner {
  width: 100px;
  height: 100px;
  margin: 0.625rem auto;
}

.spinner-container--small {
  display: inline;
}
.spinner-container--small .spinner {
  width: 16px;
  height: 16px;
}