.taxFormSaveCancelButtons {
  display: flex;
  justify-content: flex-end;
}

.formDetailsWrapper {
  width: 100%;
  height: calc(100% - 3rem);
  background-color: white;
  overflow-x: auto;
}

.formDetailsView {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.saveButton {
  display: flex;
  justify-content: center;
  align-items: center;

  :global {
    .spinner {
      margin-left: 8px;
    }
  }
}
