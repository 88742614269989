.tabWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  &.hideTab {
    display: none;
  }
}
