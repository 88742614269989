@use '../../style/variables';
@use '../../style/mixins';

$line-height: 1.2em;
$number-of-lines: 3; /* agGrid headerHeight should also be set accordingly */

.gridContainer {
  :global {
    .ag-theme-bootstrap {
      .ag-header-cell-text {
        @include mixins.line-clamp($line-height, $number-of-lines);
      }

      .ag-menu-separator-cell::after {
        display: block;
        border-top: solid 1px;
        border-top-color: #babfc7;
      }
    }
  }
}
