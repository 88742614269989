@use '../../../style/variables';
@use '../../../style/mixins';

.calcPreferencesBox {
  height: 100%;
  margin: 0 10px 30px 15px;
}

.calcPreferencesOptionsContainer,
.calcPreferencesList {
  padding-top: 5px;
}

.calcPreferencesList {
  display: grid;
  grid-template-rows: repeat(4, 60px);
  gap: 8px 10px;
  grid-auto-flow: column;
  grid-auto-columns: 230px;
}

.calcPreferencesFormPanel {
  display: grid;
  gap: 8px 10px;
}

.calcPreferenecesSelect,
.numericCalcPreferences {
  :global {
    .a-form-label {
      @include mixins.line-clamp(1.1875rem, 1);
    }
  }
}

.calcPreferencesCheckbox {
  width: 220px;
}

.calcPreferencesCheckboxesGroup {
  margin-bottom: 10px;
}
