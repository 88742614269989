@use '../../style/variables';

.efile-section {
  border-radius: 3px;
  box-shadow: variables.$box-shadow-card;
  padding: 8px;
  background: variables.$color-white;

  & + & {
    margin-top: 15px;
  }
}
