@use "variables";
@use "z-indexes";

html {
  font-size: 100%;
}

body {
  background-color: variables.$default-background-color;
  color: variables.$text-color;
  font-family: variables.$appkit-font-family;
}

a {
  color: variables.$default-link-color;

  &[disabled] {
    color: variables.$color-silver !important;
    cursor: pointer;
    pointer-events: none;
  }

  &:hover {
    color: variables.$default-link-color;
  }
}

.router-root {
  display: flex;
}

.display-hidden {
  display: none;
}

.ag-react-container > :not(:first-child) {
  /* hides duplicated values in cells for rowModelType="infinite" during scroll */
  display: none !important;
}

/* fix empty space in efile mapping ag grid */
.ag-body-viewport {
  &.ag-row-no-animation {
    &.ag-layout-auto-height {
      .ag-center-cols-clipper[role='presentation'] {
        min-height: 25px !important;
      }
    }
  }
}

.ag-theme-bootstrap {
  .ag-details-row {
    padding-bottom: 0;

    &.ag-details-row-auto-height {
      padding-top: 0;

      /* This is add due to ag-grid will always display header even headerHeight is set to 0
      This css selector will not display any header in subgrid in e-file mapping */
      .ag-header {
        &.ag-pivot-off {
          display: none;
        }
      }
    }

    .ag-cell-label-container {
      padding: 0;
    }

    .ag-center-cols-container {
      min-height: auto;
    }
  }

  .ag-pinned-left-header,
  .ag-header-container {
    .ag-header-cell {
      .ag-cell-label-container {
        .ag-header-cell-label {
          display: flex;
          align-items: center;
          justify-content: center;

          .ag-header-cell-text {
            text-align: center;
            white-space: pre-wrap;
          }
        }
      }

      .ag-header-cell.calc-preference-column {
        .ag-cell-label-container {
          .ag-header-cell-label {
            .ag-header-cell-text {
              display: block;
              width: 100px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

.ag-theme-bootstrap:not(.ag-popup) {
  .ag-body-container {
    min-width: 100%;
  }
}

.ag-header-cell-capitalize {
  text-transform: capitalize;
}

.ag-react-container {
  width: 100%;
}

.grid-borderless {
  .ag-theme-bootstrap {
    .ag-row {
      border: none;
    }
  }
}

.ag-header-group-text {
  width: 100%;
  text-align: center;
}

.ag-grid-custom {
  .ag-theme-bootstrap:not(.ag-popup) {
    width: 100%;
    height: 100%;
  }

  .row + .ag-theme-bootstrap:not(.ag-popup) {
    height: calc(100% - 40px);
    margin-top: 5px;
  }

  .search-bar {
    height: 2.125rem;
    margin-right: 0;
    margin-left: auto;
    line-height: 2.125rem;
    z-index: 0;

    .search-input-wrapper {
      border: none;
      background: variables.$color-white;

      input {
        font-size: 14px;
      }
    }
  }

  .bold-cell {
    font-weight: bold;
  }

  .italicized-cell {
    font-style: italic;
  }

  .border-cell {
    border: 1px solid variables.$color-alto;
  }

  .overlined-cell {
    border-top: 2px solid variables.$color-alto;
  }

  .underlined-cell {
    border-bottom: 2px solid variables.$color-alto;
    /* !important is for forcing constant straight underline in the whole row */
    border-right-width: 0 !important;
    border-left-width: 0;
  }

  .double-underlined-cell {
    border-bottom: 6px double variables.$color-alto;
  }

  .underlined-cell-top {
    border-top: 2px solid variables.$color-alto;
    /* !important is for forcing constant straight underline in the whole row */
    border-right-width: 0 !important;
    border-left-width: 0;
    line-height: 10px !important;
  }

  .ag-theme-bootstrap .gray-cell {
    color: variables.$color-pwc-appkit-grey;
  }

  .ag-row .cell-with-indent {
    padding-left: 15px;
  }

  .ag-row .small-font-cell {
    font-size: 10px;
  }

  .ag-theme-bootstrap .ag-row.ag-row-even,
  .ag-theme-bootstrap .ag-row.ag-row-odd {
    &.ag-row-focus {
      background-color: variables.$color-white;
    }

    &.ag-row-hover {
      background-color: variables.$color-wild-sand;
    }

    &.ag-row-selected {
      background-color: variables.$color-barley-white;
    }

    --ag-selected-row-background-color: variables.$color-barley-white;
  }

  .ag-theme-bootstrap .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
    height: 100%;
  }

  .ag-grid-with-scroll-pagination {
    .ag-root {
      border-bottom: 1px solid variables.$color-grey-dark;
    }
  }

  .scroll-pagination {
    margin: 5px 0;
  }
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary:hover,
.btn-primary:active:not(:disabled):not(.disabled) {
  border-color: variables.$color-orange-1;
  background-color: variables.$color-orange-1;
}

.btn-outline-secondary,
.btn-secondary {
  border-color: variables.$color-orange-1;
  background-color: variables.$color-white;
  color: variables.$color-orange-1;

  &.disabled,
  &:disabled,
  &:hover,
  &:active:not(:disabled):not(.disabled) {
    border-color: variables.$color-orange-2;
    background: variables.$color-orange-2;
    opacity: 0.65;
  }
}

.disabled {
  pointer-events: none; /* This makes it not clickable */
  opacity: 0.6; /* This grays it out to look disabled */
}

select {
  position: relative;
  background: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+') !important;
  background-repeat: no-repeat !important;
  background-position: 99% 50% !important;
  -webkit-appearance: none;
}

.status-ok {
  color: variables.$success-color;
}

.spinner {
  margin: 0 auto;
}

.spinning {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  display: inline-block;
  animation: spin 2s infinite linear;
}

textarea.non-resizable {
  resize: none;
}

.textarea-with-error textarea {
  box-shadow: variables.$box-shadow-appkit-field-with-error;
}

/* additional general styles */
.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-70 {
  width: 70%;
}

.db-small-font {
  font-size: variables.$regular-font-size;
}

.table.table-condensed {
  margin-bottom: 3px;
}

.d-linebreak {
  white-space: pre-line;
}

.app-container {
  flex: 1;
  justify-content: space-between;
  max-width: 100vw;
}

.slide-pane {
  .pin-icon-wrapper {
    margin-right: 5px;

    .pin-icon {
      transform: rotate(45deg) translateY(2px);
      opacity: 0.4;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      &.pin-icon--active {
        color: variables.$color-orange-1;
        opacity: 1;

        &:hover {
          color: variables.$color-orange-1;
        }
      }
    }
  }
}

body.ReactModal__Body--open {
  .slide-in-overlay.left {
    margin-left: variables.$left-menu-width;
  }

  .slide-pane__content {
    height: 100%;
  }
}

.more-button {
  border: 0;
  padding: 0 0 0 5px;
  font-size: variables.$regular-font-size;
  line-height: 16px;
  cursor: pointer;
  vertical-align: top;
}

.page {
  margin-right: 0;
  margin-left: 0;
}

.page-container {
  height: calc(
    100vh - #{variables.$main-menu-height} - #{variables.$footer-total-height} - #{variables.$page-container-top-margin}
  );
  margin: variables.$page-container-top-margin 0 0;

  .a-breadcrumbs {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 8px;
  }

  .pf {
    margin-bottom: 18px;
  }
}

.page-with-grid {
  .container-fluid {
    display: flex;
    height: calc(
      100vh - #{variables.$main-menu-height} - #{variables.$page-container-top-margin} - #{variables.$footer-total-height}
    );
    flex-direction: column;
  }

  .grid-row {
    display: flex;
    flex: 100 1;
  }

  .ag-grid-custom {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }
}

/* tab/pill navigation */
.tab-content {
  padding-top: 10px;
}

.tab-hidden {
  display: none;
}

.nav-tabs .nav-link.active {
  background-color: transparent;
}

.nav-container {
  padding-bottom: 10px;
  font-family: variables.$appkit-font-family;

  .nav-tabs {
    margin: 0;
    padding: 0;
  }
}

.nav-pills {
  padding: 0 0 10px;

  .nav-link {
    border: 0;
    background-color: variables.$color-white;
    color: variables.$default-link-color;
    cursor: pointer;

    &.active {
      background-color: variables.$default-link-color;
      color: variables.$color-white;
    }
  }
}
/* tab/pill navigation end */

.single-attachment {
  height: 34px;
  line-height: 34px;
  vertical-align: middle;

  .btn-link {
    vertical-align: top;
  }

  .filename {
    vertical-align: middle;
  }

  &.single-attachment-with-image {
    .btn-link,
    .filename {
      vertical-align: bottom;
    }
  }
}

/* general info pages (access denied, under construction) */
.general-info-page {
  height: 80vh;

  .general-info-text {
    padding-top: 30vh;
    font-size: 20px;
    text-align: center;
  }

  .general-button-wrapper {
    margin-top: 1rem;
    text-align: center;
  }
}
/* general info pages */

.dashboard-spinner {
  display: flex;
  height: 150px;
  justify-content: center;
  align-items: center;
}

/* layout */

.rounded-container {
  height: 100%;

  > div {
    border-radius: 3px;
    box-shadow: variables.$box-shadow-card;
    padding: 8px;
    background: variables.$color-white;
  }
}

.scrollable {
  overflow-y: auto;
}

/* row with cards styles for decreased padding */
.row-with-cards {
  display: flex;
  flex-wrap: wrap;
  padding-right: 7.5px;
  padding-left: 7.5px;

  .card-wrapper {
    display: flex;
    flex-direction: column;
    padding-right: 7.5px;
    padding-left: 7.5px;
  }

  .card.card-full-height {
    height: 100%;
  }

  .details-card {
    &.card-wrapper {
      margin-bottom: 15px;
    }

    .field-label {
      font-weight: bold;
    }
  }
}
/* additional general styles end */

/* bootstrap component overrides */
.card {
  margin-bottom: 15px;
  border: none;
  border-radius: 3px;
  box-shadow: variables.$box-shadow-card;
  font-size: 14px;
  line-height: 28px;

  .card-header {
    border-bottom: 1px solid variables.$card-header-bottom-border-color;
    padding: 10px 20px;
    background-color: variables.$color-white;
    color: variables.$panel-headers-font-color;
    font-size: variables.$card-header-font-size;
    line-height: 37px;
  }
}

.chosenValuesText {
  font-size: 0.85rem;
  font-weight: 400;
}

.badge {
  font-weight: 700;

  &.status-info {
    background-color: variables.$color-blue;
  }

  &.status-badge {
    display: inline-table;
    font-size: 1rem;
  }

  &.table-badge {
    font-size: 0.85rem;
    font-weight: 400;
  }
}

.a-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: variables.$appkit-modal-background;
  overflow: auto;
  z-index: z-indexes.$modal-z-index;

  &.modal-closed {
    display: none;
  }

  .modal-backdrop {
    background-color: transparent;
    z-index: z-indexes.$modal-z-index;
  }

  .modal-dialog {
    min-width: 42.5rem;
    z-index: z-indexes.$modal-z-index + 1;

    .modal-content {
      border: 0;
      border-radius: 0.125rem;
      box-shadow: variables.$box-shadow-modal;
    }
  }
}

.a-select-list-out.open {
  z-index: z-indexes.$appkit-select-options-in-modal-z-index;
}

.form-control {
  height: 38px;
  border-color: variables.$form-control-border-color;

  &[type='checkbox'] {
    width: auto;
    height: auto;
  }
}

.form-asterisk {
  color: variables.$color-punch;
}

.form-text {
  margin-bottom: 10px;
}

.invalid-feedback {
  display: block;
  margin: 0.625rem 0 0;
  color: variables.$color-pwc-appkit-red;
  font-size: 0.75rem;
}

.u-border-bottom {
  border-bottom: 2px solid variables.$color-concrete;
}
/* bootstrap component overrides end */

.a-dropdown {
  .select-toggle {
    border: none;
    border-radius: 0;
    background-color: variables.$color-concrete;
  }
}

.a-dropdown.open {
  .select-toggle {
    box-shadow: 0 0 0 0.125rem variables.$color-orange-1;
  }
}

/* appkit select overrides */
.a-dropdown.appkit-select-custom {
  .select-toggle {
    background-color: variables.$appkit-select-background-color;
    cursor: pointer;
  }

  .select-toggle-place-holder {
    user-select: none;
  }

  &.open {
    .select-toggle {
      background-color: initial;
    }
  }

  &.disabled {
    cursor: not-allowed;

    .select-toggle-text-wrapper,
    .select-toggle-icon {
      cursor: not-allowed;
    }
  }

  &.with-error {
    .select-toggle {
      box-shadow: variables.$box-shadow-appkit-field-with-error;
    }
  }
}

.sdk-custom-select-wrapper {
  &.with-error {
    .Select-control {
      box-shadow: variables.$box-shadow-appkit-field-with-error;
    }
  }
}

/* appkit select overrides end */

.cell-icon-button-group {
  position: relative;
  bottom: 3px;
}

.cell-icon-button,
.cell-icon-button:active,
.cell-icon-button:focus {
  border: 0;
  outline: 0;
  padding: 0;
  background: transparent;
}

.cell-icon-svg {
  width: 21px;
  height: 21px;

  &.align-bottom {
    vertical-align: bottom;
  }
}

.cell-icon {
  margin-right: 5px;
  fill: variables.$color-dove-gray;

  & > path {
    fill: variables.$color-dove-gray;
  }
}

.cell-icon-with-link {
  margin-right: 5px;
  padding: 0;
  color: variables.$color-black;
}

.cell-icon-with-pointer {
  color: variables.$color-orange-1;
  cursor: pointer;
}

/* icon sizes */
@for $i from 1 through variables.$largest-icon-size {
  .icon-size-#{$i} {
    font-size: #{$i}px;
  }
}

/* appkit alert custom styles */
#PopWrapper {
  position: absolute;
}

.appkit-alert-custom-wrapper {
  position: fixed;
  top: 6px;
  right: 6px;
  z-index: z-indexes.$alert-z-index;
}

.a-countdown-wrapper {
  display: none !important;
}
/* appkit alert custom styles end */

.add-button-column {
  display: flex;
  justify-content: flex-end;

  .add-button {
    margin-bottom: 15px;

    & + .add-button {
      margin-left: 10px;
    }
  }

  &.align-center {
    align-items: center;

    .add-button {
      margin-bottom: 0;
    }
  }
}

.grid-auto-sizing-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 200px;
  margin: 0 auto;
}

.ag-row.ag-row-odd.shaded-row {
  background-color: variables.$color-grey-yellow;
}

.modal-field {
  margin-bottom: 1rem;
}

.a-light-panel .a-tooltip-inner {
  color: variables.$color-red;
}

.right-column-add-button {
  display: flex;
  padding: 0;
  padding-right: 15px;
  flex-direction: column;
}

.edit-form-add-button {
  margin-top: -10px;
  margin-right: 0 !important;
  padding-right: 15px;
  padding-left: 15px;
}

.right-column-edit-form {
  position: relative;
  flex: 1;
  margin-right: 15px;
  border-radius: 3px;
  box-shadow: variables.$box-shadow-card;
  background: variables.$color-white;
  overflow-y: scroll;

  & > form {
    position: absolute;
    width: calc(100% - 30px);

    .buttons-group {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.centered-link {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 15px;
}

.global-spinner-wrapper {
  width: 100%;
  height: calc(100vh - #{variables.$main-menu-height} - #{variables.$footer-line-height});
  background-color: variables.$color-concrete;
  z-index: 2;

  .global-spinner {
    height: calc(
      100% - #{variables.$footer-margin} - #{variables.$footer-margin} - #{variables.$footer-line-height}
    );
  }
}

#navigation-panel-root {
  position: sticky;
  flex-shrink: 0;
  top: #{variables.$main-menu-height};
  width: variables.$slide-in-width;
  height: calc(100vh - #{variables.$main-menu-height});
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
  background-color: variables.$color-white;
  z-index: 1;
  overflow-y: auto;

  #navigator-wrapper > * {
    min-width: calc(#{variables.$slide-in-width} - 24px);
    padding: 0 12px;
  }

  #navigation-panel-context-wrapper > *:first-child {
    min-width: calc(#{variables.$slide-in-width} - 24px);
    padding: 12px 12px 0 12px;
  }

  hr {
    margin: 2px 0 5px 0;
  }
}

.navigator-tab-context-wrapper {
  position: sticky;
  background-color: white;
  z-index: 1;

  &.navigator-tab-context-wrapper-pinned {
    top: 0;
  }
}

.session-warning-banner {
  .a-banner-hide {
    display: none;
  }

  top: variables.$main-menu-height;
  z-index: 9001;
}

.session-warning-overlay {
  position: fixed;
  top: variables.$main-menu-height;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* prevents 'select all' option from overlapping with other options
TODO: SLT-1382 Move this fix to SDK Packages repository (ag-grid package) */
.ag-theme-bootstrap .ag-filter .ag-filter-header-container {
  height: auto;
}

.a-tab-bar-item[disabled] {
  color: #dad9d9 !important;
  cursor: not-allowed !important;
  pointer-events: all !important;

  &:hover {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.input-without-arrows::-webkit-outer-spin-button,
.input-without-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-without-arrows[type='number'] {
  -moz-appearance: textfield;
}

.appkit-modal-without-footer {
  .modal-footer {
    display: none;
  }
}

.ag-header-group-cell-with-group,
.ag-header-group-cell-with-group + .ag-header-group-cell-no-group {
  margin-top: 5px;
  border-left: solid thin variables.$color-ag-header-group-cell-with-group;
}

.ag-header-group-cell-with-group .ag-header-group-cell-label {
  display: grid;
  justify-items: center;
  align-items: center;
  font-weight: bold;
}

.row-overflow {
  overflow: auto;
}

.progress-tracker-horizontal-large {
  margin: 0.5rem auto;
}

.react-pdf__Page {
  display: flex;
  justify-content: center;
}

.info-message {
  margin-bottom: 0.5rem;
  color: variables.$color-grey-inactive;
  font-size: 12px;
}

.sdk-custom-input-tooltip-content,
.sdk-custom-select-tooltip-content {
  color: variables.$color-dark-1;
}

.a-tooltip.a-tooltip-show {
  max-width: calc(100% - 55px);

  &.offset-for-grid {
    &.top {
      margin-bottom: 5px;
    }

    &.bottom {
      margin-top: 5px;
    }
  }
}
