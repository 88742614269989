@use '../../../../../style/variables';

.formColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-width: 350px;

  h6 {
    grid-column: 1/3;
  }
}

.gridInputSelectField {
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 7px 0;

  :global {
    .a-form-error,
    .invalid-feedback {
      grid-column: 2;
    }

    .a-form-label {
      grid-column: 1;
      margin: 0 0 0 20px;
    }
  }
}

.columnDisabledLabels {
  h6 {
    color: variables.$disabled-color;
  }

  :global {
    .a-form-label {
      color: variables.$disabled-color;
    }
  }
}
