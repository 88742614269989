@use '../../../style/variables';

.panel {
  :global {
    .slide-pane__header {
      flex-direction: row-reverse; /* reversing to switch places of the title and icon */
      border-bottom: 1px solid variables.$color-light-grey;
      padding: 0 20px;
      background: variables.$color-white;
    }

    .slide-pane__close {
      padding-left: 0;
    }

    .slide-pane__title-wrapper {
      text-align: left;
    }

    .slide-pane__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
      overflow: hidden;
    }

    textarea {
      height: 2.125rem;
    }

    textarea[name='filingInformation'] {
      height: 13rem;
    }
  }
}

.fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'form schema'
    'due-dates due-dates'
    'name name'
    'attributes attributes'
    'text-areas text-areas';
  gap: 15px;
  padding: 15px 20px 60px;
  overflow: auto;
}

.filingForm {
  grid-area: form;
}

.schemaId {
  grid-area: schema;
}

.dueDates {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    'due-date-label due-date-extended-label due-date-second-extended-label'
    'due-date due-date-extended due-date-second-extended';
  grid-area: due-dates;
  column-gap: 15px;
}

.dueDateLabel {
  grid-area: due-date-label;
}

.dueDate {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-area: due-date;
}

.dueDateExtendedLabel {
  grid-area: due-date-extended-label;
}

.dueDateExtended {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-area: due-date-extended;
}

.dueDateSecondExtendedLabel {
  grid-area: due-date-second-extended-label;
}

.dueDateSecondExtended {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-area: due-date-second-extended;
}

.dueDateSelect {
  width: 80px;
}

.dueDateText {
  margin: 0 10px;
  font-size: 0.75rem;
}

.name {
  grid-area: name;
}

.attributes {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas:
    'general-attributes-title general-attributes-title efile-attributes-title efile-attributes-title efile-attributes-title'
    'return-attributes-label filing-methods-label linked-return . efile-development-label'
    'return-attributes filing-methods linked-return unlinked-return-params efile-development'
    'filing-attributes-title filing-attributes-title filing-attributes-title filing-attributes-title filing-attributes-title'
    'return-type filing-type business-type period-type tax-type';
  grid-area: attributes;
}

.sectionTitle {
  margin-bottom: 5px;
  font-weight: 500;
}

.generalAttributesTitle {
  @extend .sectionTitle;

  grid-area: general-attributes-title;
}

.returnAttributesLabel {
  grid-area: return-attributes-label;
}

.returnAttributes {
  grid-area: return-attributes;
}

.filingMethodsLabel {
  grid-area: filing-methods-label;
}

.filingMethods {
  grid-area: filing-methods;
}

.efileMandated {
  margin-left: 25px;
}

.efileAttributesTitle {
  @extend .sectionTitle;

  grid-area: efile-attributes-title;
}

.linkedReturn {
  grid-area: linked-return;

  :global {
    .a-radio-wrapper {
      margin: 0;
    }

    .a-radio-wrapper + .a-radio-wrapper {
      margin-top: 0.625rem;
    }
  }
}

.unlinkedReturnParams {
  grid-area: unlinked-return-params;
}

.efileDevelopmentLabel {
  grid-area: efile-development-label;
}

.efileDevelopment {
  grid-area: efile-development;
}

.filingAttributesTitle {
  @extend .sectionTitle;

  grid-area: filing-attributes-title;
}

.returnType {
  grid-area: return-type;
}

.filingType {
  grid-area: filing-type;
}

.taxType {
  grid-area: tax-type;
}

.businessType {
  grid-area: business-type;
}

.periodType {
  grid-area: period-type;
}

.textAreas {
  display: grid;
  gap: 10px;
  grid-area: text-areas;
}

.buttons {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: flex-end;
  border-top: 1px solid variables.$color-light-grey;
  padding: 10px 20px;
  background-color: variables.$color-white;
}

.filingAttributesGroup {
  display: grid;
  padding-left: 10px;
  grid-template-columns: 1fr;
  row-gap: 10px;
}

.separator {
  padding-left: 26px;
  font-size: 14px;
}

.calcOptionsBanner {
  bottom: 55px;
  z-index: 0;
}
