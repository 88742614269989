.unassignButton {
  margin-left: 0.5em;
}

.gridSpacerTop {
  padding-top: 15px;
}

.gridSpacerBottom {
  padding-bottom: 15px;
}

.pagesWrapper {
  height: 100%;
}

.pdfPagesFieldMappingTreeContainer {
  max-width: 250px;
  margin-right: 30px;
  padding: 5px;
}

.pagesContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.fieldsContainer {
  height: 100%;

  .fieldsForm {
    height: 100%;
    margin: 0;
    overflow: scroll;
  }
}

.datasetContainer {
  padding: 0 2px 0 2px;
}
