@use '../../style/_variables.scss';

$pdf-min-view-port: 1370px;
$transition-time: 1s;
$bookmarks-width: 340px;
$bookmarks-small-viewport-width: 250px;

canvas {
  position: relative;
  width: 90% !important;
  height: auto !important;
  transition: $transition-time ease-out;
}

.pdfPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45px;
}

.toolbarElementTooltip {
  z-index: 999;
}

.pdfViewerContainer {
  display: flex;
  position: fixed;
  top: variables.$main-menu-height;
  right: 0;
  bottom: 0;
  left: variables.$slide-in-width;
  color: white;
  transition: $transition-time ease-out;
  z-index: 0;
  flex-direction: row;
  justify-content: center;
  overflow-x: hidden;

  .noPdf {
    display: flex;
    align-self: center;
    color: variables.$color-mine-shaft;
  }
}

.pdfViewerBookmarks {
  display: flex;
  position: fixed;
  top: calc(#{variables.$main-menu-height} + 10px);
  left: calc(#{variables.$slide-in-width} + 10px);
  width: $bookmarks-width;
  transition: $transition-time ease-in;
  z-index: 999;

  .pdfNoBookmarksAvailable {
    padding: 20px;
    color: variables.$color-orange-1;
    text-align: center;
  }
}

.pdfViewerContainerExpanded {
  left: 0;
  background-color: variables.$color-wild-sand;
  transition: $transition-time ease-in;
  z-index: 999;

  .pdfViewerBookmarks {
    left: 20px;
    transition: $transition-time ease-in;
  }
}

.pdfViewerToolbarContainer {
  display: flex;
  position: relative;
  margin-top: 10px;
  z-index: 999;
  justify-content: center;

  .pdfViewerToolbar {
    display: flex;
    position: fixed;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    z-index: 9999;

    .toolbarElement {
      width: 40px;
      height: 35px;
      color: variables.$color-pale-sky;
      text-align: center;
    }

    .toolbarElement:hover {
      color: variables.$color-black;
    }
  }
}

@media (min-width: $pdf-min-view-port) {
  .pdfViewerContainer:not(.pdfViewerContainerExpanded).pdfBookmarksOpen {
    left: variables.$slide-in-width + $bookmarks-width;
  }
}

@media (max-width: $pdf-min-view-port) {
  .pdfViewerBookmarks {
    width: $bookmarks-small-viewport-width;
  }
}
