@use '../../../style/variables';

.headerParam {
  display: flex;

  :global {
    .global-context-dropdown {
      display: flex;
      min-width: 250px;
      height: 28px;
      margin: 0 25px 0 10px;
      align-items: center;

      .a-form-label {
        margin: 0 10px 1px 0;
        font-size: variables.$medium-font-size;
      }

      .Select-value {
        font-size: variables.$medium-font-size;
      }

      .spinner-container {
        display: flex;
      }
    }
  }
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
}

.leftSide {
  display: flex;
  align-items: center;
}

.screenTitle {
  flex: 1;
  font-size: x-large;
  text-align: center;
}
