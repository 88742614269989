@use "../style/variables";

.action-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  .app-current-object-data {
    display: flex;
    margin-right: 15px;
    padding-right: 15px;
    padding-left: 14px;
    color: variables.$color-black;
    flex-direction: column;
    font-size: 11px;
    line-height: 20px;
    white-space: nowrap;
    flex: 1;
    flex-shrink: 1;
    overflow: hidden;

    .favourite-star-icon {
      cursor: pointer;

      &.shadow {
        opacity: 0.2;
      }
    }

    .current-object-data-name-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 11px;

      .favourite-star-icon {
        flex-shrink: 0;
      }

      .current-object-data-name {
        display: block;
        margin-left: 5px;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .current-object {
      margin-left: 2px;
    }
  }

  .actions-container {
    flex: 1;
    justify-content: flex-end;

    & > .spinner-container {
      margin-right: 0.625rem;

      .spinner {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.user-menu-popover-avatar {
  cursor: pointer;
}

.user-menu-client-avatar {
  margin-left: 5px;
}
