.partnershipsModal {
  width: 750px;
  max-width: 750px;

  :global {
    .ag-grid-custom .ag-theme-bootstrap:not(.ag-popup) {
      height: 600px;
    }
  }
}
