.filingAttributesGroup {
  display: grid;
  padding-left: 10px;
  grid-template-columns: 1fr;
  row-gap: 10px;
}

.separator {
  padding-left: 26px;
  font-size: 14px;
}
