@use '../../../../style/variables';

.baseEfileValidationError {
  position: absolute;
  top: 2px;
  left: 1px;
  width: 10px;
  font-size: 12px;
  z-index: 1;
  pointer-events: none;

  &::before {
    pointer-events: none;
  }
}

.eFileValidationErrorIconForBoolean {
  @extend .baseEfileValidationError;

  top: 1px;
  left: -3px;
  width: 9px;
}
