.buttonsGroup {
  &.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 24px 32px;
  }

  &.fullWidth {
    display: flex;

    .buttonWrapper {
      flex: 1;
    }
  }

  button {
    margin: 0; /* resetting the margins to 0 because of SLT-10526 */
  }

  .buttonWrapper + .buttonWrapper {
    margin-left: 0.75rem;
  }
}
