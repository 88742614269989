.formListDropdown {
  width: 550px;
  margin-right: 20px;
  justify-content: center;

  label {
    display: inline;
  }
}

.formListDropdownWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}
