.dropdownsContainer {
  display: flex;
  justify-content: space-between;
}

.dropdowns {
  display: flex;

  :global {
    .sdk-custom-select-global-context-dropdown {
      width: 220px;
      margin-right: 5px;
      margin-bottom: 0;

      .global-context-dropdown-wrapper {
        width: 100%;
      }
    }
  }

  .dataItemDropdown {
    width: 300px;
  }
}

.formWrapper {
  height: calc(100% - 3rem);
}

.pageSection {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  margin-bottom: 15px;

  :global {
    .spinner-container--small {
      height: 20px;
    }
  }
}

.ofPages {
  font-size: 14px;
}

.pageDropdown {
  :global {
    .sdk-custom-select-global-context-dropdown {
      width: 90px;
      margin-right: 5px;
      margin-bottom: 0;

      .global-context-dropdown-wrapper {
        width: 100%;
      }
    }
  }
}
