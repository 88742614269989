@use "../../../style/variables";

.pageWrapper {
  display: flex;
  flex-direction: column;
  padding: 24px 64px 24px 32px;
  gap: 20px;
  flex: 1;

  > * {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.tabs {
  border: 1px solid variables.$color-alto;
  border-radius: variables.$border-radius-size-select;
  padding: 24px 50px;
  background-color: variables.$color-white;
}
