@use '../style/variables';

.taxSummaryGrid {
  :global {
    .ag-theme-bootstrap .ag-rich-select .ag-rich-select-list {
      width: 320px;
    }
  }
}

.actionsWrapper {
  display: flex;
  justify-content: space-between;
  margin: 15px auto;
}

.suppressZerosCheckbox {
  padding: 0 10px 0 5px;
  background-color: variables.$color-light-grey;
  user-select: none;

  span:first-child {
    align-self: normal;
  }

  span:nth-child(2) {
    color: variables.$color-black;
    font-size: 12px;
  }
}
