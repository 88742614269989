@use '../../../style/_variables.scss';

.constantsContentWrapper {
  justify-content: center;

  :global {
    .ag-theme-bootstrap {
      .ag-cell-value {
        border-left: 1px solid variables.$color-mercury;
      }
    }
  }
}

.constantsForm {
  padding-bottom: 15px;
}

.constantsFormFieldsContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.constantsControlsContainer {
  width: 250px;
  margin-right: 25px;
}

.constantsSmallField {
  flex-grow: 1;

  input[name='value'] {
    text-align: right;
  }
}

.constantsMediumField {
  flex-grow: 2;
}

.constantsFilingAttributesAndCalcPrefsContainer {
  display: flex;
}

.constantsCalcGroup {
  display: flex;
  max-height: 300px;
  margin: 0 10px 10px 10px;
  flex-flow: column wrap;
}

.constantsCalcPreferencesContainer {
  width: 15vw;
}
