@use "variables";

.widget {
  .card-body {
    padding-top: 0;
  }

  .widget-pie-chart-container {
    padding-top: 1.25rem;
  }

  .widget-pie-chart {
    position: relative;
    width: 78px;
    height: 78px;
    margin: 0 auto;
    cursor: pointer;
  }

  .widget-total-percentage {
    position: absolute;
    top: 26px;
    width: 100%;
    font-size: 1.4rem;
    text-align: center;
  }

  .widget-status-counts {
    margin-top: 6px;
    text-align: center;
  }

  .widget-status-label {
    font-size: 0.9rem;
    text-align: center;
  }

  .widget-link-renderer {
    padding: 0;
    font-size: variables.$regular-font-size;
  }

  @media only screen and (min-width: variables.$breakpoint-xxl) {
    .widget-status-label {
      font-size: 1rem;

      span {
        display: inline;
      }
    }
  }

  /* AgGrid tweaks */
  .ag-theme-bootstrap {
    padding-top: 5px;
  }

  .ag-cell-value {
    &:first-child {
      text-align: left;
    }
  }
}
