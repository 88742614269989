:global {
  .update-return-panel {
    .slide-pane__content {
      padding: 0;
    }
  }
}

.updateReturnsContentContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .updateReturnsContentTip {
    margin: 1rem;
    font-size: 14px;
    font-weight: 500;
  }

  :global {
    .grid-auto-sizing-wrapper {
      width: 100%;
      max-width: 100% !important;

      .ag-grid-custom {
        height: 100%;
      }
    }
  }
}

.updateReturnsPanelButtonGroup {
  display: flex;
  margin: 1rem;
  justify-content: flex-end;

  :global {
    .spinner-container {
      height: auto;
    }
  }
}
