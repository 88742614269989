@use '../../style/variables';

.dropzone {
  margin: 15px 0;
  padding: 8px;
}

.fileFormat {
  font-size: 0.8rem;

  .fileFormatHeader {
    font-weight: bold;
  }

  p {
    margin: 0;
  }
}

.cancelLink {
  margin-right: 8px;
}

.uploadFormDropdown {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 600px;
  margin-bottom: 10px;

  label {
    font-size: variables.$medium-font-size;
    font-weight: bold;
  }
}

.uploadFormSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  border-radius: 3px;
  box-shadow: variables.$box-shadow-card;
  padding: 8px;
  background: variables.$color-white;

  & + & {
    margin-top: 15px;
  }

  .loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
  }
}
