@use '../style/variables';

.notificationItemWrapper {
  :global {
    .a-list-item {
      display: grid;
    }
  }

  .notificationItem {
    grid-template-columns: 48px 1fr 32px;
    grid-template-rows: auto;
    grid-template-areas:
      'notificationIcon notificationContent notificationClear'
      '. notificationFooter notificationDate';
    margin: 1px 1px 20px 1px;
    border-radius: 0.125rem;
    box-shadow: 0 0.0625rem 0.125rem 0.0625rem rgb(0 0 0 / 8%);

    .notificationIcon {
      grid-area: notificationIcon;
      align-self: start;

      & > span {
        pointer-events: none;
      }
    }

    .notificationIconInfo {
      background-color: variables.$color-pwc-appkit-blue-lighter;

      & > span {
        color: variables.$color-pwc-appkit-blue-darker;
      }
    }

    .notificationIconSuccess {
      background-color: variables.$color-pwc-appkit-success-background;

      & > span {
        color: variables.$color-pwc-appkit-green;
      }
    }

    .notificationIconWarning {
      background-color: variables.$color-pwc-appkit-warning-background;

      & > span {
        color: variables.$color-pwc-appkit-yellow;
      }
    }

    .notificationContent {
      grid-area: notificationContent;
      overflow: hidden;

      :global {
        .primary {
          font-size: 14px;
        }

        .secondary {
          width: 100%;
          font-size: 12px;
          white-space: break-spaces;
          overflow: hidden;
        }
      }
    }

    .notificationClear {
      grid-area: notificationClear;
      align-self: start;
    }

    .notificationViewMore {
      grid-area: notificationViewMore;
      color: variables.$color-orange-1;
      font-size: 12px;
      cursor: pointer;
    }

    .notificationLink {
      grid-area: notificationLink;
      justify-self: end;
      color: variables.$color-orange-1;
      font-size: 12px;
      cursor: pointer;
    }

    .notificationDate {
      grid-area: notificationDate;
      justify-self: end;
      font-size: 12px;
    }

    .notificationFooter {
      grid-area: notificationFooter;

      & > span + span {
        margin-left: 10px;
      }
    }
  }
}
