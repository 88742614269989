@use "../../../style/variables";

.utilitiesWrapper {
  margin: 24px 0 24px 0;
  padding: 24px 50px;
}

.utilitiesReturnsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 15px 0;
}

.utilitiesReturnsWrapper:nth-last-child(1) {
  margin: 0;
}

.btnDefaultWidth {
  width: 140px;
}
