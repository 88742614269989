.dataItemsUsedInGfunctions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  margin-bottom: 20px;
}

.deleteDatasetModal {
  max-width: 70vw;

  :global {
    .modal-content {
      width: 70vw;
    }

    .modal-body {
      max-height: 80vh;
      overflow: auto;
    }
  }
}
