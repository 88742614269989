.controlsRow {
  display: flex;

  .topBarField {
    width: 100%;
    margin-right: 5px;
  }
}

.gridLabel {
  position: relative;
  top: 35px;
}

.formContainer {
  :global {
    .spinner-container {
      display: flex;
      height: 120px;
    }
  }
}
