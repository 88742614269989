@use 'z-indexes';

/* COLOR VARIABLES */
$color-alto: #ddd;
$color-alto-2: #e0e0e0;
$color-athens-gray: #f8f9fa;
$color-black: #000;
$color-concrete: #f2f2f2;
$color-dove-gray: #6e6e6e;
$color-endeavour: #005cb9;
$color-green-haze: #00b33c;
$color-mercury: #e6e6e6;
$color-mine-shaft: #333;
$color-pearl-lusta: #fcf8e3;
$color-punch: #dc3545;
$color-rain-forest: #006b53;
$color-shark: #292b2c;
$color-silver-sand: #c7c8ca;
$color-silver-chalice: #9e9e9e;
$color-silver: #ccc;
$color-white: #fff;
$color-ghost: #ced4da;
$color-red: #d61918;
$color-blue: #007bff;
$color-bootstrap-grey-300: #dee2e6;
$color-opacity-black: rgba(0, 0, 0, 0.2);
$color-viking: #66b5d5;
$color-fuzzy-wuzzy-brown: #c54f4a;
$color-fern: #66ab5b;
$color-pale-sky: #6c757d;
$color-barley-white: #fff0cc;
$color-grey-info: #eee;
$color-dark-1: #3f3f40;
$color-dark-2: #303030;
$color-dark-3: #2d2d2d;
$color-grey-inactive: #787878;
$color-yellow: #ffc83d;
$color-orange-1: #d04a02;
$color-orange-2: #e93b05;
$color-light-orange: #feebe4;
$color-red-dark: #972d26;
$color-grey-dark: #c8c8c8;
$color-border-grey: #f3f3f3;
$color-light-grey: #e0e0e0;
$color-wild-sand: #f5f5f5;
$color-ag-header-group-cell-with-group: #b8b6b6;
$color-bittersweet: #ff6363;
$color-gray: #929292;
$color-grey-yellow: #fffef4;
$color-pwc-appkit-red: #c52a1a;
$color-pwc-appkit-yellow: #ffbf1f;
$color-pwc-appkit-green: #22992e;
$color-pwc-appkit-grey: #bdbdbd;
$color-pwc-appkit-grey-darker: #7d7d7d;
$color-pwc-appkit-red-darker: #741910;
$color-pwc-appkit-red-lighter: #f7c8c4;
$color-pwc-appkit-blue-darker: #003dab;
$color-pwc-appkit-blue-lighter: #b3dcf9;
$color-pwc-appkit-warning-background: rgba($color-pwc-appkit-yellow, 0.15);
$color-pwc-appkit-success-background: rgba($color-pwc-appkit-green, 0.15);
$color-pwc-appkit-failed-background: rgba($color-pwc-appkit-red, 0.15);
$color-return-status-tracker-default-color: #dedede;
$color-return-status-tracker-not-started-color: #e0301e;
$color-return-status-tracker-in-progress-color: #ffb600;
$color-return-status-tracker-complete-color: #4eb523;

/* COMPONENT-RELATED COLORS */
$active-tab-color: $color-blue;
$default-background-color: $color-concrete;
$default-link-color: $color-orange-1;
$navbar-background-color: $color-white;
$navbar-link-active: $color-white;
$navbar-link-color: $color-silver-sand;
$navbar-link-hover: $color-white;
$notification-danger-color: $color-punch;
$notification-neutral-color: $color-silver;
$notification-neutral-text: $color-shark;
$row-horizontal-color: $color-mercury;
$success-color: $color-rain-forest;
$text-color: $color-mine-shaft;
$disabled-color: $color-silver;
$attachment-upload-color: $color-silver-sand;
$attachment-upload-error-color: $color-punch;
$form-control-border-color: $color-ghost;
$error-status-color: $color-pwc-appkit-red;
$success-status-color: $color-pwc-appkit-green;
$warning-status-color: $color-pwc-appkit-yellow;

$dropdown-row-selection-highlight-color: $color-red;
$dropdown-row-hover-text-color: $color-white;
$dropdown-row-hover-highlight-color: $color-bootstrap-grey-300;

$submenu-background-color: $color-white;
$submenu-option-color: $color-mine-shaft;
$submenu-option-hover-color: $color-white;
$submenu-row-hover-highlight-color: $color-red;
$submenu-shadow-color: $color-opacity-black;

$secondary-menu-background-color: $color-white;
$secondary-menu-item-border-color: $color-mercury;

$panel-headers-font-color: $color-mine-shaft;

$dashboard-boxes-font-color: $color-white;

$section-header-color: $color-orange-1;

$main-menu-logo-border-color: $color-border-grey;

$left-menu-link-color-hover: $color-mine-shaft;
$left-menu-link-background-hover: $color-wild-sand;

$card-header-bottom-border-color: rgba(0, 0, 0, 0.125);

$appkit-secondary-input-bg-color: $color-wild-sand;

$appkit-select-background-color: $color-wild-sand;
$appkit-select-option-active: $color-grey-info;
$appkit-select-tag-color: $color-orange-1;

$appkit-modal-background: rgba(0, 0, 0, 0.3);

$option-selected-background: hsla(22deg, 70%, 88%, 0.5);

/* FONTS */
$regular-font-size: 12px;
$medium-font-size: 14px;
$card-header-font-size: 18px;
$appkit-font-family: 'PwC Helvetica Neue', helvetica, arial, sans-serif;

/* BREAKPOINTS */
$bootstrap-md: 768px;
$bootstrap-lg: 992px;
$bootstrap-xl: 1200px;

$min-controls-breakpoint: 1572px;
$min-add-tax-entry-breakpoint: 1410px;
$min-beginning-balances-breakpoint: 1500px;

$breakpoint-xxl: 1440px;

/* WIDTHS */
$left-menu-width: 80px;
$left-menu-expanded-width: 250px;
$global-search-bar-width: 400px;
$secondary-menu-link-gap-width: 25px;
$slide-in-width: 400px;

/* HEIGHTS */
$main-menu-height: 50px;
$main-menu-logo-width: 40px;
$report-value-cell-min-height: 18px;
$left-menu-link-height: 60px;
$footer-line-height: 20px;
$footer-margin: 15px;
$footer-margin-total: 30px;
$tabs-with-margins-height: 67px;
$horizontal-rule-height: 33px;
$page-title-height: 24px;
$global-context-height: 44px;
$page-container-top-margin: 8px;
$banner-mode-height: 46px;

$footer-total-height: (#{$footer-line-height + $footer-margin-total});
$page-container-with-global-context-top-margin: (
  #{$main-menu-height + $page-container-top-margin + $page-title-height + $global-context-height}
);

/* SIZES */
$border-radius-size-select: 3px;

/* IMAGE SIZES */
$client-icon-width: 44px;
$client-icon-height: 44px;
$client-avatar-width: 28px;
$client-avatar-height: 28px;

/* ICON SIZES */
$largest-icon-size: 60;

/* SHADOWS */
$mdl-box-shadow--2dp: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);

/* ANIMATION TIMES */
$slide-in-pin-time: 0.3s;
$slide-in-overlay-blur-time: 0.5s;

/* BOX SHADOWS */
$box-shadow-light: 0 2px 4px rgba(0, 0, 0, 0.1), 0 3px 10px rgba(0, 0, 0, 0.05);
$box-shadow-card: 0 1px 2px 1px rgba(0, 0, 0, 0.08);
$box-shadow-card-hover: 0 3px 10px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.08);
$box-shadow-appkit-field: 0 0 0 0.125rem $color-orange-1;
$box-shadow-appkit-field-with-error: 0 0 0 0.125rem $color-pwc-appkit-red;
$box-shadow-slide-in: 0 8px 8px rgba(0, 0, 0, 0.5);
$box-shadow-modal: 0 0.4375rem 1.125rem 0.0625rem rgba(0, 0, 0, 0.16);
$box-shadow-menu: rgba(0, 0, 0, 0.2) 0 2px 4px;

/* watermark */
$watermark-text-stroke-color: rgb(210, 210, 210);

/* efile */
$efile-current-return-filing-sub-gap-value: 16px;
