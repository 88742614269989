.bottomSlideInContainer {
  display: flex;
  position: relative;
  max-height: 55vh;
  margin-top: 16px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.08);
  padding: 0 15px;
  background: #fff;
  overflow: auto;
  flex-direction: column;
  flex: 1 1 auto;

  :global {
    .buttons-group {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .a-h4 {
      padding-top: 0.5rem;
    }

    hr {
      margin: 10px 0 10px 0;
    }
  }
}

.bottomSlideInContainerCompact {
  flex-grow: 0;
  min-height: 36px;
}

.compactModeIcon {
  position: absolute;
  top: 0;
  right: 5px;
  padding: 8px 12px;
  z-index: 1;
  cursor: pointer;
}

.stickyIcon {
  position: sticky;
  top: 20px;
  right: 5px;
  height: 0;
  padding: 0 5px 0;
  text-align: right;
  z-index: 2;
}
