$dropdown-width: 320px;

.wrapper {
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;

  :global {
    .global-context-dropdown {
      min-width: $dropdown-width;
    }
  }
}

.dropdownWrapper {
  min-width: $dropdown-width;
  padding: 0 15px;
}
