.header {
  display: flex;
  flex-direction: row;
}

.pastSubmissions {
  text-align: end;
}

.transmissionsSelectContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    margin-inline-end: 16px;
    font-size: 12px;
  }
}

.transmissionSelect {
  display: inline-block;
  min-width: 217px;
  text-align: initial;
}
