@use '../../../style/_variables.scss';

.sdk-custom-select-global-context-dropdown {
  display: flex;
  justify-content: space-between;

  .global-context-dropdown-wrapper {
    width: calc(100% - 100px);

    .a-form-label {
      display: none;
    }

    .sdk-small-custom-context-wrapper {
      i.sdk-custom-select-appkit-icon.icon-down-chevron-fill {
        top: 0.4rem;
      }

      .global-context-small-select {
        font-size: 12px;

        .Select-control {
          height: 28px;
        }

        .Select-placeholder {
          line-height: 28px;
        }

        .Select-menu-outer {
          top: 35px;
        }

        .Select-input {
          height: 28px;
        }
      }
    }
  }
}

.sdk-custom-select-wrapper {
  .is-disabled {
    opacity: 0.6;

    .Select-value-label {
      color: variables.$color-silver-chalice !important;
    }
  }

  .is-disabled + i {
    opacity: 0.6;
  }
}
