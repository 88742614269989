@use '../../../../style/variables';

.compileContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1;
  gap: 10px;
}

.compileHeader {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.compilationInformation {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  font-size: variables.$regular-font-size;
}

.compilationSingleInformation {
  display: flex;
  gap: 8px;
}
