.dataInfoSubtext {
  position: relative;
  top: 50%;
  text-align: center;
}

.dataInfoSubTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dataInfoSubTextParagraph {
  margin: 0;
  padding: 0;
  font-size: 24px;
}

.infoSubTextCircleIcon {
  margin-right: 10px;
}

.infoSubTextSpinner {
  margin: 7px 10px 0 0;

  .spinnerContainerSmall .spinner {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }
}
