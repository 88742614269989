.formInputs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 10px;

  :global {
    .a-checkbox-wrapper {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 18.75px 1fr;
      gap: 10px;
      margin: 0;
      justify-items: center;

      .a-checkbox-text {
        padding-left: 0;
        font-size: 0.75rem;
        align-self: end;
      }

      .a-checkbox {
        order: 2;

        .a-checkbox-inner {
          position: absolute;
        }
      }
    }
  }
}

.gridSection {
  margin: 10px 0 10px 0;
}

.aboveGridRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  p {
    margin-bottom: 0;
  }
}
