@use '../../style/variables';
@use "../../style/z-indexes";

.menu {
  position: sticky;
  top: 0;
  z-index: z-indexes.$top-menu-z-index;

  .a-header-wrapper {
    white-space: nowrap;
    justify-content: space-between;

    .a-brand-container {
      margin-right: 5px;
    }

    .menu-tabs-wrapper {
      width: auto;
      flex: 3 1;

      .tabs-container {
        width: 100%;
        float: left;
      }
    }
  }

  &-logo {
    width: variables.$main-menu-logo-width;
  }

  .secondary-menu {
    .select-toggle {
      background-color: variables.$secondary-menu-background-color;

      .select-toggle-text-wrapper {
        margin-right: 0;
      }

      .select-toggle-icon {
        color: variables.$color-mine-shaft;
        font-size: 0.4375rem;
      }
    }

    .a-select-list-out {
      max-width: 180px;
    }

    .a-select-list {
      padding: 0;
      font-family: variables.$appkit-font-family;

      .a-list-item {
        padding: 0;

        a {
          display: flex;
          width: 100%;
          height: 100%;
          padding: 0 10px;
          color: inherit;
          text-decoration: none;
          align-items: center;
        }
      }
    }
  }
}

.a-header-wrapper.nested-menu {
  display: block;
  height: 100%;
  box-shadow: none;
  font-weight: 500;
}

.menu-container {
  display: flex;
  height: 100%;
  background-color: white !important;
  font-weight: 500;

  .navLink {
    display: flex;
    align-items: center;
    border-bottom: 2px solid white;
    padding: 0 20px 0 20px;
    background: transparent;
    color: variables.$color-mine-shaft;
    text-decoration: none;
  }

  .navLink:hover {
    border-bottom: 2px solid black;
  }
}

div[role='menu'] {
  min-width: 200px;
  cursor: pointer;

  hr {
    margin: 0;
    border-top: 2px dotted variables.$color-grey-dark;
  }
}

div[role='menuitem'] {
  padding: 10px;
}

.sub-menu-icon {
  float: right;
}
