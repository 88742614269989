.spaceAbove {
  margin-top: 1.5em;
}

.formRow {
  display: flex;
}

.rightMargin {
  margin-right: 20px;
}

.bottomSlideUpSpinner {
  height: 400px;
}
