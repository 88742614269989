@use '../../../style/_variables.scss';

.selectableList {
  .listItem {
    padding: 0.25rem 0.625rem;
    color: variables.$color-gray;
    cursor: pointer;
  }

  .listIcon {
    display: flex;
    margin-right: 6px;
    margin-bottom: 3px;
  }

  .collapseBtn {
    border: none;
  }

  .listCollapse {
    padding-right: 0;
    padding-left: 1.25rem;
  }
}

.listLabel {
  margin-bottom: 10px;
  color: variables.$color-dark-3;
  font-size: 12px;
}
