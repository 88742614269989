@use '../../../style/variables';

$error-status-background-color: variables.$error-status-color;
$success-status-background-color: variables.$success-status-color;
$warning-status-background-color: variables.$warning-status-color;

$status-text-color: variables.$color-white;
$warning-status-text-color: variables.$color-black;

.validationStatus {
  display: inline;
  margin: 0.5rem;
  padding: 5px;
  color: $status-text-color;
  font-weight: bold;
}

.passStatus {
  @extend .validationStatus;

  background-color: $success-status-background-color;
}

.failStatus {
  @extend .validationStatus;

  background-color: $error-status-background-color;
}

.passWithWarningsStatus {
  @extend .validationStatus;

  background-color: $warning-status-background-color;
  color: $warning-status-text-color;
}

.loadingText {
  margin-left: 10px;
  animation: loading 1.5s infinite alternate;
}

@keyframes loading {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}
