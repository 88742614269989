.buttonsRow {
  display: flex;
  width: 100%;
  margin-top: 16px;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.createMemberDatasetButton {
  align-self: flex-end;
}
