.gridsContainer {
  display: flex;
  height: 100%;
  gap: 20px;
}

.generalGrid {
  flex: 2;
}

.detailsSection {
  display: flex;
  flex-direction: column;
  flex: 3;
  height: 100%;
}

.detailsGrid {
  flex: 1;
}

.detailsRow {
  display: flex;
  gap: 20px;
}

.detailsInput {
  flex: 1;
}

.addJurisdictionButton {
  align-self: flex-end;
  margin: 15px 0;
}

.buttonsGroup {
  align-self: flex-end;
  margin-top: 15px;
}
