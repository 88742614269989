@use '../../../style//variables';

.popupWrapper {
  position: fixed;
  min-width: 8em;
  border: 1px solid variables.$color-opacity-black;
  box-shadow: variables.$box-shadow-menu;
  background-color: variables.$color-white;
  font-size: 0.875em;
  text-align: left;
  z-index: 100001;

  li {
    z-index: 100002;

    :first-child {
      display: flex;
      padding: 8px 5px;
    }

    span {
      margin-right: 5px;
    }
  }

  .itemDisabled {
    color: variables.$color-pwc-appkit-grey;
    pointer-events: none;
  }

  hr {
    margin: 0;
  }

  li:not(.itemDisabled):hover {
    background-color: variables.$color-ghost;
    cursor: pointer;
  }
}
