@use "../../../style/variables";

.appkit-datepicker-custom-wrapper {
  position: relative;
  line-height: 28px;

  .react-calendar {
    position: absolute;
    top: 45px;
    width: 100%;
    z-index: 9999;
  }

  .react-date-picker {
    width: 100%;

    .a-date-picker-header {
      width: 100%;
    }

    .a-date-picker-header-date-input {
      cursor: pointer;
    }
  }

  .date-picker {
    width: 100%;

    .react-date-picker--open {
      width: 60%;
    }

    .a-date-picker-header {
      width: 100%;

      button:focus {
        outline: none;
      }

      .a-date-picker-header-date-input {
        cursor: pointer;
      }
    }
  }

  .calendar {
    width: 100%;
    min-width: 475px;
    background: variables.$color-white;

    .react-calendar__month-view {
      text-align: center;

      .r-c-days {
        > button {
          height: 50px;
          outline: none;
        }
      }
    }

    .react-calendar__year-view {
      .react-calendar__year-view__months {
        > button {
          height: 67px;
          outline: none;
        }
      }
    }

    .react-calendar__decade-view {
      .react-calendar__decade-view__years {
        > button {
          height: 67px;
          outline: none;
        }
      }
    }

    .r-c-nav-bar {
      .a-center-button-wrapper {
        button:focus {
          outline: none;
        }
      }
    }
  }

  .clear-icon {
    position: absolute;
    top: 2px;
    right: 45px;
    color: variables.$color-silver-chalice;
    cursor: pointer;

    &:hover {
      color: variables.$color-orange-1;
    }
  }

  .calendar-icon {
    position: absolute;
    top: 2px;
    right: 15px;
    cursor: pointer;

    &:hover {
      color: variables.$color-orange-1;
    }
  }
}
