.editCheckboxGroupForm {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.editCheckboxGroupFormFields {
  display: flex;
  flex-direction: column;
}
