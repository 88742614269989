.formDetailsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    'formName formName watermark watermark'
    'formDescription formDescription formDescription formDescription'
    'returnType filingType businessType periodType'
    'taxType formStatus inDevelopmentMessageAndefileSupported inDevelopmentMessageAndefileSupported'
    'pdfName pdfName pdfName pdfName'
    'swpCalc swpCalc swpFormId swpFormId';
  gap: 10px 10px;
  padding: 1em;
}

.formName {
  grid-area: formName;
}

.swpFormId {
  grid-area: swpFormId;
}

.formDescription {
  grid-area: formDescription;
}

.returnType {
  grid-area: returnType;
}

.filingType {
  grid-area: filingType;
}

.businessType {
  grid-area: businessType;
}

.periodType {
  grid-area: periodType;
}

.taxType {
  grid-area: taxType;
}

.pdfName {
  grid-area: pdfName;
}

.inDevelopmentMessageAndefileSupported {
  grid-area: inDevelopmentMessageAndefileSupported;
}

.efileSupported {
  margin-top: 1rem;
}

.swpCalc {
  grid-area: swpCalc;
}

.watermark {
  grid-area: watermark;
}
