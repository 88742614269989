@use '../../../style/variables';

.addEditReturnPanelTooltip {
  width: 500px;
  /* !important is necessary because <Tooltip> component hard-codes z-index to 9999 with inline style property */
  z-index: 99999 !important;
}

.panel {
  :global {
    .slide-pane__header {
      flex-direction: row-reverse; /* reversing to switch places of the title and icon */
      border-bottom: 1px solid variables.$color-light-grey;
      padding: 0 20px;
      background: variables.$color-white;
    }

    .slide-pane__close {
      padding-left: 0;
    }

    .slide-pane__title-wrapper {
      text-align: left;
    }

    .slide-pane__content {
      display: flex;
      flex-direction: column;
      padding: 0;
      overflow: hidden;
    }

    .navigation-tabs-wrapper {
      margin-top: 32px;
      padding: 0 20px;
    }
  }

  .sectionTitle {
    font-weight: 500;
  }

  .fields {
    grid-row-gap: 20px;
    display: grid;
    height: 100%;
    padding: 15px 20px;
    padding-bottom: 60px;
    overflow: auto;
    grid-auto-rows: max-content;
  }

  .returnOptionsTabFields {
    @extend .fields;
  }

  .calcOptionsTabFields {
    @extend .fields;

    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'return-type return-type'
      'business-type business-type'
      'tax-type tax-presence'
      'common-calc-preferences common-calc-preferences'
      'state-calc-preferences state-calc-preferences';
  }

  .returnType {
    grid-area: return-type;
  }

  .businessType {
    grid-area: business-type;
  }

  .taxType {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    grid-area: tax-type;
  }

  .taxPresence {
    display: grid;
    grid-auto-rows: max-content;
    gap: 10px;
    grid-area: tax-presence;
  }

  .commonCalcPreferences {
    grid-area: common-calc-preferences;
  }

  .stateCalcPreferences {
    grid-area: state-calc-preferences;
  }

  .buttons {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid variables.$color-light-grey;
    padding: 10px 20px;
    background-color: variables.$color-white;
  }

  .filingMethodTitle {
    display: flex;
    font-size: 0.875rem;
  }

  .nonFilingDisplay {
    display: flex;
    font-size: 0.875rem;
  }

  .nonFilingTooltipIcon {
    margin-left: 0.25rem;
    color: variables.$color-orange-1;
  }

  .dueDateSection {
    display: flex;
    align-items: baseline;
  }

  .dueDateTooltipIcon {
    margin-left: 0.25rem;
    color: variables.$color-orange-1;
  }

  .filingInformation {
    border: 1px solid variables.$color-pwc-appkit-grey;
    border-radius: 4px;
    padding: 1rem;
    background-color: variables.$color-concrete;
  }

  .filingInfoHeader {
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .filingInfoMessage {
    margin-left: 1.5rem;
    font-size: 0.75rem;
  }

  .filingInformationIcon {
    color: variables.$color-pwc-appkit-grey-darker;
    opacity: 0.7;
  }

  .calcOptionsBanner {
    bottom: 55px;
    z-index: 0;
  }
}
